/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  DateTime: { input: any; output: any }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any }
}

export enum AccountUpdateMode {
  MigratePlan = 'MIGRATE_PLAN',
  UpdateBillingMethod = 'UPDATE_BILLING_METHOD',
  UpdateSchoolDetails = 'UPDATE_SCHOOL_DETAILS',
  UpdateUserSeats = 'UPDATE_USER_SEATS'
}

export type BannerAlertCreateInput = {
  authenticatedUsersOnly?: InputMaybe<Scalars['Boolean']['input']>
  bodyText?: InputMaybe<Scalars['JSON']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>
  guestsOnly?: InputMaybe<Scalars['Boolean']['input']>
  kind?: InputMaybe<Scalars['String']['input']>
  publishDate?: InputMaybe<Scalars['DateTime']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  userDismissable?: InputMaybe<Scalars['Boolean']['input']>
}

export type BannerAlertOrderByInput = {
  authenticatedUsersOnly?: InputMaybe<OrderDirection>
  createdAt?: InputMaybe<OrderDirection>
  expiresAt?: InputMaybe<OrderDirection>
  guestsOnly?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  kind?: InputMaybe<OrderDirection>
  publishDate?: InputMaybe<OrderDirection>
  status?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
  userDismissable?: InputMaybe<OrderDirection>
}

export type BannerAlertUpdateArgs = {
  data: BannerAlertUpdateInput
  where: BannerAlertWhereUniqueInput
}

export type BannerAlertUpdateInput = {
  authenticatedUsersOnly?: InputMaybe<Scalars['Boolean']['input']>
  bodyText?: InputMaybe<Scalars['JSON']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>
  guestsOnly?: InputMaybe<Scalars['Boolean']['input']>
  kind?: InputMaybe<Scalars['String']['input']>
  publishDate?: InputMaybe<Scalars['DateTime']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  userDismissable?: InputMaybe<Scalars['Boolean']['input']>
}

export type BannerAlertWhereInput = {
  AND?: InputMaybe<Array<BannerAlertWhereInput>>
  NOT?: InputMaybe<Array<BannerAlertWhereInput>>
  OR?: InputMaybe<Array<BannerAlertWhereInput>>
  authenticatedUsersOnly?: InputMaybe<BooleanFilter>
  createdAt?: InputMaybe<DateTimeNullableFilter>
  expiresAt?: InputMaybe<DateTimeNullableFilter>
  guestsOnly?: InputMaybe<BooleanFilter>
  id?: InputMaybe<IdFilter>
  kind?: InputMaybe<StringNullableFilter>
  publishDate?: InputMaybe<DateTimeFilter>
  status?: InputMaybe<StringNullableFilter>
  title?: InputMaybe<StringFilter>
  userDismissable?: InputMaybe<BooleanFilter>
}

export type BannerAlertWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type BlogPostCreateInput = {
  content?: InputMaybe<Scalars['JSON']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  excerpt?: InputMaybe<Scalars['JSON']['input']>
  learningAreas?: InputMaybe<LearningAreaRelateToManyForCreateInput>
  publishDate?: InputMaybe<Scalars['DateTime']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagRelateToManyForCreateInput>
  thumbnailImage?: InputMaybe<ImageAssetRelateToOneForCreateInput>
  title?: InputMaybe<Scalars['String']['input']>
}

export type BlogPostManyRelationFilter = {
  every?: InputMaybe<BlogPostWhereInput>
  none?: InputMaybe<BlogPostWhereInput>
  some?: InputMaybe<BlogPostWhereInput>
}

export type BlogPostOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  publishDate?: InputMaybe<OrderDirection>
  slug?: InputMaybe<OrderDirection>
  status?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
}

export type BlogPostRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<BlogPostWhereUniqueInput>>
  create?: InputMaybe<Array<BlogPostCreateInput>>
}

export type BlogPostRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<BlogPostWhereUniqueInput>>
  create?: InputMaybe<Array<BlogPostCreateInput>>
  disconnect?: InputMaybe<Array<BlogPostWhereUniqueInput>>
  set?: InputMaybe<Array<BlogPostWhereUniqueInput>>
}

export type BlogPostRelateToOneForCreateInput = {
  connect?: InputMaybe<BlogPostWhereUniqueInput>
  create?: InputMaybe<BlogPostCreateInput>
}

export type BlogPostRelateToOneForUpdateInput = {
  connect?: InputMaybe<BlogPostWhereUniqueInput>
  create?: InputMaybe<BlogPostCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type BlogPostUpdateArgs = {
  data: BlogPostUpdateInput
  where: BlogPostWhereUniqueInput
}

export type BlogPostUpdateInput = {
  content?: InputMaybe<Scalars['JSON']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  excerpt?: InputMaybe<Scalars['JSON']['input']>
  learningAreas?: InputMaybe<LearningAreaRelateToManyForUpdateInput>
  publishDate?: InputMaybe<Scalars['DateTime']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagRelateToManyForUpdateInput>
  thumbnailImage?: InputMaybe<ImageAssetRelateToOneForUpdateInput>
  title?: InputMaybe<Scalars['String']['input']>
}

export type BlogPostWhereInput = {
  AND?: InputMaybe<Array<BlogPostWhereInput>>
  NOT?: InputMaybe<Array<BlogPostWhereInput>>
  OR?: InputMaybe<Array<BlogPostWhereInput>>
  createdAt?: InputMaybe<DateTimeNullableFilter>
  id?: InputMaybe<IdFilter>
  learningAreas?: InputMaybe<LearningAreaManyRelationFilter>
  publishDate?: InputMaybe<DateTimeFilter>
  slug?: InputMaybe<StringFilter>
  status?: InputMaybe<StringNullableFilter>
  tags?: InputMaybe<TagManyRelationFilter>
  thumbnailImage?: InputMaybe<ImageAssetWhereInput>
  title?: InputMaybe<StringFilter>
}

export type BlogPostWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type BooleanFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>
  not?: InputMaybe<BooleanFilter>
}

export type ConfigCreateInput = {
  videoEndImage?: InputMaybe<ImageAssetRelateToOneForCreateInput>
}

export type ConfigOrderByInput = {
  id?: InputMaybe<OrderDirection>
}

export type ConfigUpdateArgs = {
  data: ConfigUpdateInput
  where: ConfigWhereUniqueInput
}

export type ConfigUpdateInput = {
  videoEndImage?: InputMaybe<ImageAssetRelateToOneForUpdateInput>
}

export type ConfigWhereInput = {
  AND?: InputMaybe<Array<ConfigWhereInput>>
  NOT?: InputMaybe<Array<ConfigWhereInput>>
  OR?: InputMaybe<Array<ConfigWhereInput>>
  id?: InputMaybe<IdFilter>
  videoEndImage?: InputMaybe<ImageAssetWhereInput>
}

export type ConfigWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type CreateAccountInput = {
  address?: InputMaybe<CustomerAddressInput>
  billingEmail?: InputMaybe<Scalars['String']['input']>
  collectionMethod?: InputMaybe<Scalars['String']['input']>
  educationalRole: Scalars['String']['input']
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  password: Scalars['String']['input']
  phone?: InputMaybe<Scalars['String']['input']>
  plan: Scalars['String']['input']
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>
  schoolName?: InputMaybe<Scalars['String']['input']>
  subscriptionUserSeats: Scalars['Int']['input']
  taxNumber?: InputMaybe<Scalars['String']['input']>
  yearLevel?: InputMaybe<Scalars['String']['input']>
}

export type CreateInitialUserInput = {
  email?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
}

export type CustomerAccessPermissionCreateInput = {
  canViewContent?: InputMaybe<Scalars['Boolean']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  customer?: InputMaybe<CustomerRelateToOneForCreateInput>
  invitationAcceptedAt?: InputMaybe<Scalars['DateTime']['input']>
  invitationEmail?: InputMaybe<Scalars['String']['input']>
  invitationIssuedAt?: InputMaybe<Scalars['DateTime']['input']>
  invitationToken?: InputMaybe<Scalars['String']['input']>
  isCustomerAdmin?: InputMaybe<Scalars['Boolean']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  user?: InputMaybe<UserRelateToOneForCreateInput>
}

export type CustomerAccessPermissionManyRelationFilter = {
  every?: InputMaybe<CustomerAccessPermissionWhereInput>
  none?: InputMaybe<CustomerAccessPermissionWhereInput>
  some?: InputMaybe<CustomerAccessPermissionWhereInput>
}

export type CustomerAccessPermissionOrderByInput = {
  canViewContent?: InputMaybe<OrderDirection>
  createdAt?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  invitationAcceptedAt?: InputMaybe<OrderDirection>
  invitationEmail?: InputMaybe<OrderDirection>
  invitationIssuedAt?: InputMaybe<OrderDirection>
  invitationToken?: InputMaybe<OrderDirection>
  isCustomerAdmin?: InputMaybe<OrderDirection>
  status?: InputMaybe<OrderDirection>
  updatedAt?: InputMaybe<OrderDirection>
}

export type CustomerAccessPermissionRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<CustomerAccessPermissionWhereUniqueInput>>
  create?: InputMaybe<Array<CustomerAccessPermissionCreateInput>>
}

export type CustomerAccessPermissionRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<CustomerAccessPermissionWhereUniqueInput>>
  create?: InputMaybe<Array<CustomerAccessPermissionCreateInput>>
  disconnect?: InputMaybe<Array<CustomerAccessPermissionWhereUniqueInput>>
  set?: InputMaybe<Array<CustomerAccessPermissionWhereUniqueInput>>
}

export type CustomerAccessPermissionUpdateArgs = {
  data: CustomerAccessPermissionUpdateInput
  where: CustomerAccessPermissionWhereUniqueInput
}

export type CustomerAccessPermissionUpdateInput = {
  canViewContent?: InputMaybe<Scalars['Boolean']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  customer?: InputMaybe<CustomerRelateToOneForUpdateInput>
  invitationAcceptedAt?: InputMaybe<Scalars['DateTime']['input']>
  invitationEmail?: InputMaybe<Scalars['String']['input']>
  invitationIssuedAt?: InputMaybe<Scalars['DateTime']['input']>
  invitationToken?: InputMaybe<Scalars['String']['input']>
  isCustomerAdmin?: InputMaybe<Scalars['Boolean']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  user?: InputMaybe<UserRelateToOneForUpdateInput>
}

export type CustomerAccessPermissionWhereInput = {
  AND?: InputMaybe<Array<CustomerAccessPermissionWhereInput>>
  NOT?: InputMaybe<Array<CustomerAccessPermissionWhereInput>>
  OR?: InputMaybe<Array<CustomerAccessPermissionWhereInput>>
  canViewContent?: InputMaybe<BooleanFilter>
  createdAt?: InputMaybe<DateTimeFilter>
  customer?: InputMaybe<CustomerWhereInput>
  id?: InputMaybe<IdFilter>
  invitationAcceptedAt?: InputMaybe<DateTimeNullableFilter>
  invitationEmail?: InputMaybe<StringNullableFilter>
  invitationIssuedAt?: InputMaybe<DateTimeNullableFilter>
  invitationToken?: InputMaybe<StringNullableFilter>
  isCustomerAdmin?: InputMaybe<BooleanFilter>
  status?: InputMaybe<StringFilter>
  updatedAt?: InputMaybe<DateTimeNullableFilter>
  user?: InputMaybe<UserWhereInput>
}

export type CustomerAccessPermissionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type CustomerAddressInput = {
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  postcode: Scalars['String']['input']
  state?: InputMaybe<Scalars['String']['input']>
  street1: Scalars['String']['input']
}

export type CustomerCreateInput = {
  accessPermissions?: InputMaybe<CustomerAccessPermissionRelateToManyForCreateInput>
  activatedAt?: InputMaybe<Scalars['DateTime']['input']>
  activationIssuedAt?: InputMaybe<Scalars['DateTime']['input']>
  activationToken?: InputMaybe<Scalars['String']['input']>
  availableUserSeats?: InputMaybe<Scalars['Int']['input']>
  billingEmail?: InputMaybe<Scalars['String']['input']>
  collectionMethod?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  fileAssetDownloads?: InputMaybe<FileAssetDownloadRelateToManyForCreateInput>
  hasFreeAccess?: InputMaybe<Scalars['Boolean']['input']>
  loginHistory?: InputMaybe<LoginHistoryRelateToManyForCreateInput>
  mostRecentSubscription?: InputMaybe<SubscriptionRelateToOneForCreateInput>
  name?: InputMaybe<Scalars['String']['input']>
  plan?: InputMaybe<Scalars['String']['input']>
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>
  schoolAddressCity?: InputMaybe<Scalars['String']['input']>
  schoolAddressCountry?: InputMaybe<Scalars['String']['input']>
  schoolAddressPostcode?: InputMaybe<Scalars['String']['input']>
  schoolAddressState?: InputMaybe<Scalars['String']['input']>
  schoolAddressStreet1?: InputMaybe<Scalars['String']['input']>
  schoolAddressStreet2?: InputMaybe<Scalars['String']['input']>
  schoolName?: InputMaybe<Scalars['String']['input']>
  signupStatus?: InputMaybe<Scalars['String']['input']>
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>
  stripeTaxId?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus?: InputMaybe<Scalars['String']['input']>
  subscriptionUserSeats?: InputMaybe<Scalars['Int']['input']>
  subscriptions?: InputMaybe<SubscriptionRelateToManyForCreateInput>
  taxNumber?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  validTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type CustomerOrderByInput = {
  activatedAt?: InputMaybe<OrderDirection>
  activationIssuedAt?: InputMaybe<OrderDirection>
  activationToken?: InputMaybe<OrderDirection>
  availableUserSeats?: InputMaybe<OrderDirection>
  billingEmail?: InputMaybe<OrderDirection>
  collectionMethod?: InputMaybe<OrderDirection>
  createdAt?: InputMaybe<OrderDirection>
  hasFreeAccess?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  name?: InputMaybe<OrderDirection>
  plan?: InputMaybe<OrderDirection>
  purchaseOrderNumber?: InputMaybe<OrderDirection>
  schoolAddressCity?: InputMaybe<OrderDirection>
  schoolAddressCountry?: InputMaybe<OrderDirection>
  schoolAddressPostcode?: InputMaybe<OrderDirection>
  schoolAddressState?: InputMaybe<OrderDirection>
  schoolAddressStreet1?: InputMaybe<OrderDirection>
  schoolAddressStreet2?: InputMaybe<OrderDirection>
  schoolName?: InputMaybe<OrderDirection>
  signupStatus?: InputMaybe<OrderDirection>
  stripeCustomerId?: InputMaybe<OrderDirection>
  stripeTaxId?: InputMaybe<OrderDirection>
  subscriptionStatus?: InputMaybe<OrderDirection>
  subscriptionUserSeats?: InputMaybe<OrderDirection>
  taxNumber?: InputMaybe<OrderDirection>
  type?: InputMaybe<OrderDirection>
  updatedAt?: InputMaybe<OrderDirection>
  validTo?: InputMaybe<OrderDirection>
}

export type CustomerRelateToOneForCreateInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>
  create?: InputMaybe<CustomerCreateInput>
}

export type CustomerRelateToOneForUpdateInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>
  create?: InputMaybe<CustomerCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type CustomerUpdateArgs = {
  data: CustomerUpdateInput
  where: CustomerWhereUniqueInput
}

export type CustomerUpdateInput = {
  accessPermissions?: InputMaybe<CustomerAccessPermissionRelateToManyForUpdateInput>
  activatedAt?: InputMaybe<Scalars['DateTime']['input']>
  activationIssuedAt?: InputMaybe<Scalars['DateTime']['input']>
  activationToken?: InputMaybe<Scalars['String']['input']>
  availableUserSeats?: InputMaybe<Scalars['Int']['input']>
  billingEmail?: InputMaybe<Scalars['String']['input']>
  collectionMethod?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  fileAssetDownloads?: InputMaybe<FileAssetDownloadRelateToManyForUpdateInput>
  hasFreeAccess?: InputMaybe<Scalars['Boolean']['input']>
  loginHistory?: InputMaybe<LoginHistoryRelateToManyForUpdateInput>
  mostRecentSubscription?: InputMaybe<SubscriptionRelateToOneForUpdateInput>
  name?: InputMaybe<Scalars['String']['input']>
  plan?: InputMaybe<Scalars['String']['input']>
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>
  schoolAddressCity?: InputMaybe<Scalars['String']['input']>
  schoolAddressCountry?: InputMaybe<Scalars['String']['input']>
  schoolAddressPostcode?: InputMaybe<Scalars['String']['input']>
  schoolAddressState?: InputMaybe<Scalars['String']['input']>
  schoolAddressStreet1?: InputMaybe<Scalars['String']['input']>
  schoolAddressStreet2?: InputMaybe<Scalars['String']['input']>
  schoolName?: InputMaybe<Scalars['String']['input']>
  signupStatus?: InputMaybe<Scalars['String']['input']>
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>
  stripeTaxId?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus?: InputMaybe<Scalars['String']['input']>
  subscriptionUserSeats?: InputMaybe<Scalars['Int']['input']>
  subscriptions?: InputMaybe<SubscriptionRelateToManyForUpdateInput>
  taxNumber?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  validTo?: InputMaybe<Scalars['DateTime']['input']>
}

export type CustomerWhereInput = {
  AND?: InputMaybe<Array<CustomerWhereInput>>
  NOT?: InputMaybe<Array<CustomerWhereInput>>
  OR?: InputMaybe<Array<CustomerWhereInput>>
  accessPermissions?: InputMaybe<CustomerAccessPermissionManyRelationFilter>
  activatedAt?: InputMaybe<DateTimeNullableFilter>
  activationIssuedAt?: InputMaybe<DateTimeNullableFilter>
  activationToken?: InputMaybe<StringNullableFilter>
  availableUserSeats?: InputMaybe<IntFilter>
  billingEmail?: InputMaybe<StringFilter>
  collectionMethod?: InputMaybe<StringFilter>
  createdAt?: InputMaybe<DateTimeFilter>
  fileAssetDownloads?: InputMaybe<FileAssetDownloadManyRelationFilter>
  hasFreeAccess?: InputMaybe<BooleanFilter>
  id?: InputMaybe<IdFilter>
  loginHistory?: InputMaybe<LoginHistoryManyRelationFilter>
  mostRecentSubscription?: InputMaybe<SubscriptionWhereInput>
  name?: InputMaybe<StringFilter>
  plan?: InputMaybe<StringFilter>
  purchaseOrderNumber?: InputMaybe<StringNullableFilter>
  schoolAddressCity?: InputMaybe<StringNullableFilter>
  schoolAddressCountry?: InputMaybe<StringNullableFilter>
  schoolAddressPostcode?: InputMaybe<StringNullableFilter>
  schoolAddressState?: InputMaybe<StringNullableFilter>
  schoolAddressStreet1?: InputMaybe<StringNullableFilter>
  schoolAddressStreet2?: InputMaybe<StringNullableFilter>
  schoolName?: InputMaybe<StringNullableFilter>
  signupStatus?: InputMaybe<StringNullableFilter>
  stripeCustomerId?: InputMaybe<StringNullableFilter>
  stripeTaxId?: InputMaybe<StringNullableFilter>
  subscriptionStatus?: InputMaybe<StringNullableFilter>
  subscriptionUserSeats?: InputMaybe<IntFilter>
  subscriptions?: InputMaybe<SubscriptionManyRelationFilter>
  taxNumber?: InputMaybe<StringNullableFilter>
  type?: InputMaybe<StringFilter>
  updatedAt?: InputMaybe<DateTimeNullableFilter>
  validTo?: InputMaybe<DateTimeNullableFilter>
}

export type CustomerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>
}

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  not?: InputMaybe<DateTimeFilter>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
}

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  not?: InputMaybe<DateTimeNullableFilter>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
}

export type DateTimeWithZoneFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>
  gt?: InputMaybe<Scalars['DateTime']['input']>
  gte?: InputMaybe<Scalars['DateTime']['input']>
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>
  lt?: InputMaybe<Scalars['DateTime']['input']>
  lte?: InputMaybe<Scalars['DateTime']['input']>
  not?: InputMaybe<DateTimeWithZoneFilter>
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>
}

export type EventCreateInput = {
  bannerImage?: InputMaybe<ImageAssetRelateToOneForCreateInput>
  bodyText?: InputMaybe<Scalars['JSON']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  eventLinks?: InputMaybe<EventLinkRelateToManyForCreateInput>
  eventLocations?: InputMaybe<EventLocationRelateToManyForCreateInput>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventLinkCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  event?: InputMaybe<EventRelateToOneForCreateInput>
  image?: InputMaybe<ImageAssetRelateToOneForCreateInput>
  printable?: InputMaybe<PrintableRelateToOneForCreateInput>
  title?: InputMaybe<Scalars['String']['input']>
  video?: InputMaybe<VideoRelateToOneForCreateInput>
}

export type EventLinkManyRelationFilter = {
  every?: InputMaybe<EventLinkWhereInput>
  none?: InputMaybe<EventLinkWhereInput>
  some?: InputMaybe<EventLinkWhereInput>
}

export type EventLinkOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
}

export type EventLinkRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<EventLinkWhereUniqueInput>>
  create?: InputMaybe<Array<EventLinkCreateInput>>
}

export type EventLinkRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<EventLinkWhereUniqueInput>>
  create?: InputMaybe<Array<EventLinkCreateInput>>
  disconnect?: InputMaybe<Array<EventLinkWhereUniqueInput>>
  set?: InputMaybe<Array<EventLinkWhereUniqueInput>>
}

export type EventLinkRelateToOneForCreateInput = {
  connect?: InputMaybe<EventLinkWhereUniqueInput>
  create?: InputMaybe<EventLinkCreateInput>
}

export type EventLinkRelateToOneForUpdateInput = {
  connect?: InputMaybe<EventLinkWhereUniqueInput>
  create?: InputMaybe<EventLinkCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type EventLinkUpdateArgs = {
  data: EventLinkUpdateInput
  where: EventLinkWhereUniqueInput
}

export type EventLinkUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  event?: InputMaybe<EventRelateToOneForUpdateInput>
  image?: InputMaybe<ImageAssetRelateToOneForUpdateInput>
  printable?: InputMaybe<PrintableRelateToOneForUpdateInput>
  title?: InputMaybe<Scalars['String']['input']>
  video?: InputMaybe<VideoRelateToOneForUpdateInput>
}

export type EventLinkWhereInput = {
  AND?: InputMaybe<Array<EventLinkWhereInput>>
  NOT?: InputMaybe<Array<EventLinkWhereInput>>
  OR?: InputMaybe<Array<EventLinkWhereInput>>
  createdAt?: InputMaybe<DateTimeNullableFilter>
  event?: InputMaybe<EventWhereInput>
  id?: InputMaybe<IdFilter>
  image?: InputMaybe<ImageAssetWhereInput>
  printable?: InputMaybe<PrintableWhereInput>
  title?: InputMaybe<StringFilter>
  video?: InputMaybe<VideoWhereInput>
}

export type EventLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type EventLocationCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  event?: InputMaybe<EventRelateToOneForCreateInput>
  location?: InputMaybe<Scalars['String']['input']>
  purchaseTicketLink?: InputMaybe<Scalars['String']['input']>
  shows?: InputMaybe<EventShowRelateToManyForCreateInput>
  ticketAvailability?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventLocationManyRelationFilter = {
  every?: InputMaybe<EventLocationWhereInput>
  none?: InputMaybe<EventLocationWhereInput>
  some?: InputMaybe<EventLocationWhereInput>
}

export type EventLocationOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  location?: InputMaybe<OrderDirection>
  purchaseTicketLink?: InputMaybe<OrderDirection>
  ticketAvailability?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
}

export type EventLocationRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<EventLocationWhereUniqueInput>>
  create?: InputMaybe<Array<EventLocationCreateInput>>
}

export type EventLocationRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<EventLocationWhereUniqueInput>>
  create?: InputMaybe<Array<EventLocationCreateInput>>
  disconnect?: InputMaybe<Array<EventLocationWhereUniqueInput>>
  set?: InputMaybe<Array<EventLocationWhereUniqueInput>>
}

export type EventLocationRelateToOneForCreateInput = {
  connect?: InputMaybe<EventLocationWhereUniqueInput>
  create?: InputMaybe<EventLocationCreateInput>
}

export type EventLocationRelateToOneForUpdateInput = {
  connect?: InputMaybe<EventLocationWhereUniqueInput>
  create?: InputMaybe<EventLocationCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type EventLocationUpdateArgs = {
  data: EventLocationUpdateInput
  where: EventLocationWhereUniqueInput
}

export type EventLocationUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  event?: InputMaybe<EventRelateToOneForUpdateInput>
  location?: InputMaybe<Scalars['String']['input']>
  purchaseTicketLink?: InputMaybe<Scalars['String']['input']>
  shows?: InputMaybe<EventShowRelateToManyForUpdateInput>
  ticketAvailability?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventLocationWhereInput = {
  AND?: InputMaybe<Array<EventLocationWhereInput>>
  NOT?: InputMaybe<Array<EventLocationWhereInput>>
  OR?: InputMaybe<Array<EventLocationWhereInput>>
  createdAt?: InputMaybe<DateTimeNullableFilter>
  event?: InputMaybe<EventWhereInput>
  id?: InputMaybe<IdFilter>
  location?: InputMaybe<StringFilter>
  purchaseTicketLink?: InputMaybe<StringFilter>
  shows?: InputMaybe<EventShowManyRelationFilter>
  ticketAvailability?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export type EventLocationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type EventOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
}

export type EventRelateToOneForCreateInput = {
  connect?: InputMaybe<EventWhereUniqueInput>
  create?: InputMaybe<EventCreateInput>
}

export type EventRelateToOneForUpdateInput = {
  connect?: InputMaybe<EventWhereUniqueInput>
  create?: InputMaybe<EventCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type EventShowCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  location?: InputMaybe<EventLocationRelateToOneForCreateInput>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  timezone?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventShowManyRelationFilter = {
  every?: InputMaybe<EventShowWhereInput>
  none?: InputMaybe<EventShowWhereInput>
  some?: InputMaybe<EventShowWhereInput>
}

export type EventShowOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>
  endDate?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  startDate?: InputMaybe<OrderDirection>
  timezone?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
}

export type EventShowRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<EventShowWhereUniqueInput>>
  create?: InputMaybe<Array<EventShowCreateInput>>
}

export type EventShowRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<EventShowWhereUniqueInput>>
  create?: InputMaybe<Array<EventShowCreateInput>>
  disconnect?: InputMaybe<Array<EventShowWhereUniqueInput>>
  set?: InputMaybe<Array<EventShowWhereUniqueInput>>
}

export type EventShowUpdateArgs = {
  data: EventShowUpdateInput
  where: EventShowWhereUniqueInput
}

export type EventShowUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  endDate?: InputMaybe<Scalars['DateTime']['input']>
  location?: InputMaybe<EventLocationRelateToOneForUpdateInput>
  startDate?: InputMaybe<Scalars['DateTime']['input']>
  timezone?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventShowWhereInput = {
  AND?: InputMaybe<Array<EventShowWhereInput>>
  NOT?: InputMaybe<Array<EventShowWhereInput>>
  OR?: InputMaybe<Array<EventShowWhereInput>>
  createdAt?: InputMaybe<DateTimeNullableFilter>
  endDate?: InputMaybe<DateTimeWithZoneFilter>
  id?: InputMaybe<IdFilter>
  location?: InputMaybe<EventLocationWhereInput>
  startDate?: InputMaybe<DateTimeWithZoneFilter>
  timezone?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export type EventShowWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type EventUpdateArgs = {
  data: EventUpdateInput
  where: EventWhereUniqueInput
}

export type EventUpdateInput = {
  bannerImage?: InputMaybe<ImageAssetRelateToOneForUpdateInput>
  bodyText?: InputMaybe<Scalars['JSON']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  eventLinks?: InputMaybe<EventLinkRelateToManyForUpdateInput>
  eventLocations?: InputMaybe<EventLocationRelateToManyForUpdateInput>
  title?: InputMaybe<Scalars['String']['input']>
}

export type EventWhereInput = {
  AND?: InputMaybe<Array<EventWhereInput>>
  NOT?: InputMaybe<Array<EventWhereInput>>
  OR?: InputMaybe<Array<EventWhereInput>>
  bannerImage?: InputMaybe<ImageAssetWhereInput>
  createdAt?: InputMaybe<DateTimeNullableFilter>
  eventLinks?: InputMaybe<EventLinkManyRelationFilter>
  eventLocations?: InputMaybe<EventLocationManyRelationFilter>
  id?: InputMaybe<IdFilter>
  title?: InputMaybe<StringFilter>
}

export type EventWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type FileAssetCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  file?: InputMaybe<FileFieldInput>
  fileAssetDownloads?: InputMaybe<FileAssetDownloadRelateToManyForCreateInput>
  name?: InputMaybe<Scalars['String']['input']>
  printable?: InputMaybe<PrintableRelateToOneForCreateInput>
  state?: InputMaybe<Scalars['String']['input']>
}

export type FileAssetDownloadCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  customer?: InputMaybe<CustomerRelateToOneForCreateInput>
  fileAsset?: InputMaybe<FileAssetRelateToOneForCreateInput>
  ipAddress?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  printable?: InputMaybe<PrintableRelateToOneForCreateInput>
  user?: InputMaybe<UserRelateToOneForCreateInput>
  userAgent?: InputMaybe<Scalars['String']['input']>
}

export type FileAssetDownloadManyRelationFilter = {
  every?: InputMaybe<FileAssetDownloadWhereInput>
  none?: InputMaybe<FileAssetDownloadWhereInput>
  some?: InputMaybe<FileAssetDownloadWhereInput>
}

export type FileAssetDownloadOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  ipAddress?: InputMaybe<OrderDirection>
  name?: InputMaybe<OrderDirection>
  userAgent?: InputMaybe<OrderDirection>
}

export type FileAssetDownloadRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<FileAssetDownloadWhereUniqueInput>>
  create?: InputMaybe<Array<FileAssetDownloadCreateInput>>
}

export type FileAssetDownloadRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<FileAssetDownloadWhereUniqueInput>>
  create?: InputMaybe<Array<FileAssetDownloadCreateInput>>
  disconnect?: InputMaybe<Array<FileAssetDownloadWhereUniqueInput>>
  set?: InputMaybe<Array<FileAssetDownloadWhereUniqueInput>>
}

export type FileAssetDownloadUpdateArgs = {
  data: FileAssetDownloadUpdateInput
  where: FileAssetDownloadWhereUniqueInput
}

export type FileAssetDownloadUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  customer?: InputMaybe<CustomerRelateToOneForUpdateInput>
  fileAsset?: InputMaybe<FileAssetRelateToOneForUpdateInput>
  ipAddress?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  printable?: InputMaybe<PrintableRelateToOneForUpdateInput>
  user?: InputMaybe<UserRelateToOneForUpdateInput>
  userAgent?: InputMaybe<Scalars['String']['input']>
}

export type FileAssetDownloadWhereInput = {
  AND?: InputMaybe<Array<FileAssetDownloadWhereInput>>
  NOT?: InputMaybe<Array<FileAssetDownloadWhereInput>>
  OR?: InputMaybe<Array<FileAssetDownloadWhereInput>>
  createdAt?: InputMaybe<DateTimeNullableFilter>
  customer?: InputMaybe<CustomerWhereInput>
  fileAsset?: InputMaybe<FileAssetWhereInput>
  id?: InputMaybe<IdFilter>
  ipAddress?: InputMaybe<StringFilter>
  name?: InputMaybe<StringFilter>
  printable?: InputMaybe<PrintableWhereInput>
  user?: InputMaybe<UserWhereInput>
  userAgent?: InputMaybe<StringFilter>
}

export type FileAssetDownloadWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type FileAssetManyRelationFilter = {
  every?: InputMaybe<FileAssetWhereInput>
  none?: InputMaybe<FileAssetWhereInput>
  some?: InputMaybe<FileAssetWhereInput>
}

export type FileAssetOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  name?: InputMaybe<OrderDirection>
  state?: InputMaybe<OrderDirection>
}

export type FileAssetRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<FileAssetWhereUniqueInput>>
  create?: InputMaybe<Array<FileAssetCreateInput>>
}

export type FileAssetRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<FileAssetWhereUniqueInput>>
  create?: InputMaybe<Array<FileAssetCreateInput>>
  disconnect?: InputMaybe<Array<FileAssetWhereUniqueInput>>
  set?: InputMaybe<Array<FileAssetWhereUniqueInput>>
}

export type FileAssetRelateToOneForCreateInput = {
  connect?: InputMaybe<FileAssetWhereUniqueInput>
  create?: InputMaybe<FileAssetCreateInput>
}

export type FileAssetRelateToOneForUpdateInput = {
  connect?: InputMaybe<FileAssetWhereUniqueInput>
  create?: InputMaybe<FileAssetCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type FileAssetUpdateArgs = {
  data: FileAssetUpdateInput
  where: FileAssetWhereUniqueInput
}

export type FileAssetUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  file?: InputMaybe<FileFieldInput>
  fileAssetDownloads?: InputMaybe<FileAssetDownloadRelateToManyForUpdateInput>
  name?: InputMaybe<Scalars['String']['input']>
  printable?: InputMaybe<PrintableRelateToOneForUpdateInput>
  state?: InputMaybe<Scalars['String']['input']>
}

export type FileAssetWhereInput = {
  AND?: InputMaybe<Array<FileAssetWhereInput>>
  NOT?: InputMaybe<Array<FileAssetWhereInput>>
  OR?: InputMaybe<Array<FileAssetWhereInput>>
  createdAt?: InputMaybe<DateTimeNullableFilter>
  fileAssetDownloads?: InputMaybe<FileAssetDownloadManyRelationFilter>
  id?: InputMaybe<IdFilter>
  name?: InputMaybe<StringFilter>
  printable?: InputMaybe<PrintableWhereInput>
  state?: InputMaybe<StringNullableFilter>
}

export type FileAssetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type FileFieldInput = {
  upload: Scalars['Upload']['input']
}

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>
  gt?: InputMaybe<Scalars['Float']['input']>
  gte?: InputMaybe<Scalars['Float']['input']>
  in?: InputMaybe<Array<Scalars['Float']['input']>>
  lt?: InputMaybe<Scalars['Float']['input']>
  lte?: InputMaybe<Scalars['Float']['input']>
  not?: InputMaybe<FloatNullableFilter>
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>
}

export type HelpCategoryCreateInput = {
  blurb?: InputMaybe<Scalars['String']['input']>
  items?: InputMaybe<HelpItemRelateToManyForCreateInput>
  title?: InputMaybe<Scalars['String']['input']>
}

export type HelpCategoryOrderByInput = {
  blurb?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
}

export type HelpCategoryRelateToOneForCreateInput = {
  connect?: InputMaybe<HelpCategoryWhereUniqueInput>
  create?: InputMaybe<HelpCategoryCreateInput>
}

export type HelpCategoryRelateToOneForUpdateInput = {
  connect?: InputMaybe<HelpCategoryWhereUniqueInput>
  create?: InputMaybe<HelpCategoryCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type HelpCategoryUpdateArgs = {
  data: HelpCategoryUpdateInput
  where: HelpCategoryWhereUniqueInput
}

export type HelpCategoryUpdateInput = {
  blurb?: InputMaybe<Scalars['String']['input']>
  items?: InputMaybe<HelpItemRelateToManyForUpdateInput>
  title?: InputMaybe<Scalars['String']['input']>
}

export type HelpCategoryWhereInput = {
  AND?: InputMaybe<Array<HelpCategoryWhereInput>>
  NOT?: InputMaybe<Array<HelpCategoryWhereInput>>
  OR?: InputMaybe<Array<HelpCategoryWhereInput>>
  blurb?: InputMaybe<StringFilter>
  id?: InputMaybe<IdFilter>
  items?: InputMaybe<HelpItemManyRelationFilter>
  title?: InputMaybe<StringFilter>
}

export type HelpCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type HelpItemCreateInput = {
  answer?: InputMaybe<Scalars['JSON']['input']>
  category?: InputMaybe<HelpCategoryRelateToOneForCreateInput>
  title?: InputMaybe<Scalars['String']['input']>
}

export type HelpItemManyRelationFilter = {
  every?: InputMaybe<HelpItemWhereInput>
  none?: InputMaybe<HelpItemWhereInput>
  some?: InputMaybe<HelpItemWhereInput>
}

export type HelpItemOrderByInput = {
  id?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
}

export type HelpItemRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<HelpItemWhereUniqueInput>>
  create?: InputMaybe<Array<HelpItemCreateInput>>
}

export type HelpItemRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<HelpItemWhereUniqueInput>>
  create?: InputMaybe<Array<HelpItemCreateInput>>
  disconnect?: InputMaybe<Array<HelpItemWhereUniqueInput>>
  set?: InputMaybe<Array<HelpItemWhereUniqueInput>>
}

export type HelpItemUpdateArgs = {
  data: HelpItemUpdateInput
  where: HelpItemWhereUniqueInput
}

export type HelpItemUpdateInput = {
  answer?: InputMaybe<Scalars['JSON']['input']>
  category?: InputMaybe<HelpCategoryRelateToOneForUpdateInput>
  title?: InputMaybe<Scalars['String']['input']>
}

export type HelpItemWhereInput = {
  AND?: InputMaybe<Array<HelpItemWhereInput>>
  NOT?: InputMaybe<Array<HelpItemWhereInput>>
  OR?: InputMaybe<Array<HelpItemWhereInput>>
  category?: InputMaybe<HelpCategoryWhereInput>
  id?: InputMaybe<IdFilter>
  title?: InputMaybe<StringFilter>
}

export type HelpItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type IdFilter = {
  equals?: InputMaybe<Scalars['ID']['input']>
  gt?: InputMaybe<Scalars['ID']['input']>
  gte?: InputMaybe<Scalars['ID']['input']>
  in?: InputMaybe<Array<Scalars['ID']['input']>>
  lt?: InputMaybe<Scalars['ID']['input']>
  lte?: InputMaybe<Scalars['ID']['input']>
  not?: InputMaybe<IdFilter>
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type ImageAssetCreateInput = {
  blogPost?: InputMaybe<BlogPostRelateToOneForCreateInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  event?: InputMaybe<EventRelateToOneForCreateInput>
  eventLink?: InputMaybe<EventLinkRelateToOneForCreateInput>
  imageFile?: InputMaybe<S3ImagesFieldInput>
  learningAreas?: InputMaybe<LearningAreaRelateToManyForCreateInput>
  name?: InputMaybe<Scalars['String']['input']>
  printables?: InputMaybe<PrintableRelateToManyForCreateInput>
  sets?: InputMaybe<SetRelateToManyForCreateInput>
  videoAsset?: InputMaybe<VideoAssetRelateToOneForCreateInput>
}

export type ImageAssetOrderByInput = {
  createdAt?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  name?: InputMaybe<OrderDirection>
}

export type ImageAssetRelateToOneForCreateInput = {
  connect?: InputMaybe<ImageAssetWhereUniqueInput>
  create?: InputMaybe<ImageAssetCreateInput>
}

export type ImageAssetRelateToOneForUpdateInput = {
  connect?: InputMaybe<ImageAssetWhereUniqueInput>
  create?: InputMaybe<ImageAssetCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type ImageAssetUpdateArgs = {
  data: ImageAssetUpdateInput
  where: ImageAssetWhereUniqueInput
}

export type ImageAssetUpdateInput = {
  blogPost?: InputMaybe<BlogPostRelateToOneForUpdateInput>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  event?: InputMaybe<EventRelateToOneForUpdateInput>
  eventLink?: InputMaybe<EventLinkRelateToOneForUpdateInput>
  imageFile?: InputMaybe<S3ImagesFieldInput>
  learningAreas?: InputMaybe<LearningAreaRelateToManyForUpdateInput>
  name?: InputMaybe<Scalars['String']['input']>
  printables?: InputMaybe<PrintableRelateToManyForUpdateInput>
  sets?: InputMaybe<SetRelateToManyForUpdateInput>
  videoAsset?: InputMaybe<VideoAssetRelateToOneForUpdateInput>
}

export type ImageAssetWhereInput = {
  AND?: InputMaybe<Array<ImageAssetWhereInput>>
  NOT?: InputMaybe<Array<ImageAssetWhereInput>>
  OR?: InputMaybe<Array<ImageAssetWhereInput>>
  blogPost?: InputMaybe<BlogPostWhereInput>
  createdAt?: InputMaybe<DateTimeNullableFilter>
  event?: InputMaybe<EventWhereInput>
  eventLink?: InputMaybe<EventLinkWhereInput>
  id?: InputMaybe<IdFilter>
  learningAreas?: InputMaybe<LearningAreaManyRelationFilter>
  name?: InputMaybe<StringFilter>
  printables?: InputMaybe<PrintableManyRelationFilter>
  sets?: InputMaybe<SetManyRelationFilter>
  videoAsset?: InputMaybe<VideoAssetWhereInput>
}

export type ImageAssetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  gte?: InputMaybe<Scalars['Int']['input']>
  in?: InputMaybe<Array<Scalars['Int']['input']>>
  lt?: InputMaybe<Scalars['Int']['input']>
  lte?: InputMaybe<Scalars['Int']['input']>
  not?: InputMaybe<IntFilter>
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>
  gt?: InputMaybe<Scalars['Int']['input']>
  gte?: InputMaybe<Scalars['Int']['input']>
  in?: InputMaybe<Array<Scalars['Int']['input']>>
  lt?: InputMaybe<Scalars['Int']['input']>
  lte?: InputMaybe<Scalars['Int']['input']>
  not?: InputMaybe<IntNullableFilter>
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>
}

export enum InvitationMode {
  Redeem = 'REDEEM',
  Validate = 'VALIDATE'
}

export type InviteUsersInput = {
  canViewContent: Scalars['Boolean']['input']
  emails: Array<Scalars['String']['input']>
  isCustomerAdmin: Scalars['Boolean']['input']
}

export enum KeystoneAdminUiFieldMetaCreateViewFieldMode {
  Edit = 'edit',
  Hidden = 'hidden'
}

export enum KeystoneAdminUiFieldMetaIsNonNull {
  Create = 'create',
  Read = 'read',
  Update = 'update'
}

export enum KeystoneAdminUiFieldMetaItemViewFieldMode {
  Edit = 'edit',
  Hidden = 'hidden',
  Read = 'read'
}

export enum KeystoneAdminUiFieldMetaItemViewFieldPosition {
  Form = 'form',
  Sidebar = 'sidebar'
}

export enum KeystoneAdminUiFieldMetaListViewFieldMode {
  Hidden = 'hidden',
  Read = 'read'
}

export enum KeystoneAdminUiSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type LearningAreaCreateInput = {
  blogPosts?: InputMaybe<BlogPostRelateToManyForCreateInput>
  description?: InputMaybe<Scalars['JSON']['input']>
  image?: InputMaybe<ImageAssetRelateToOneForCreateInput>
  order?: InputMaybe<Scalars['Int']['input']>
  printables?: InputMaybe<PrintableRelateToManyForCreateInput>
  sets?: InputMaybe<SetRelateToManyForCreateInput>
  tags?: InputMaybe<TagRelateToManyForCreateInput>
  title?: InputMaybe<Scalars['String']['input']>
  videos?: InputMaybe<VideoRelateToManyForCreateInput>
}

export type LearningAreaManyRelationFilter = {
  every?: InputMaybe<LearningAreaWhereInput>
  none?: InputMaybe<LearningAreaWhereInput>
  some?: InputMaybe<LearningAreaWhereInput>
}

export type LearningAreaOrderByInput = {
  id?: InputMaybe<OrderDirection>
  order?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
}

export type LearningAreaRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<LearningAreaWhereUniqueInput>>
  create?: InputMaybe<Array<LearningAreaCreateInput>>
}

export type LearningAreaRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<LearningAreaWhereUniqueInput>>
  create?: InputMaybe<Array<LearningAreaCreateInput>>
  disconnect?: InputMaybe<Array<LearningAreaWhereUniqueInput>>
  set?: InputMaybe<Array<LearningAreaWhereUniqueInput>>
}

export type LearningAreaRelateToOneForCreateInput = {
  connect?: InputMaybe<LearningAreaWhereUniqueInput>
  create?: InputMaybe<LearningAreaCreateInput>
}

export type LearningAreaRelateToOneForUpdateInput = {
  connect?: InputMaybe<LearningAreaWhereUniqueInput>
  create?: InputMaybe<LearningAreaCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type LearningAreaUpdateArgs = {
  data: LearningAreaUpdateInput
  where: LearningAreaWhereUniqueInput
}

export type LearningAreaUpdateInput = {
  blogPosts?: InputMaybe<BlogPostRelateToManyForUpdateInput>
  description?: InputMaybe<Scalars['JSON']['input']>
  image?: InputMaybe<ImageAssetRelateToOneForUpdateInput>
  order?: InputMaybe<Scalars['Int']['input']>
  printables?: InputMaybe<PrintableRelateToManyForUpdateInput>
  sets?: InputMaybe<SetRelateToManyForUpdateInput>
  tags?: InputMaybe<TagRelateToManyForUpdateInput>
  title?: InputMaybe<Scalars['String']['input']>
  videos?: InputMaybe<VideoRelateToManyForUpdateInput>
}

export type LearningAreaWhereInput = {
  AND?: InputMaybe<Array<LearningAreaWhereInput>>
  NOT?: InputMaybe<Array<LearningAreaWhereInput>>
  OR?: InputMaybe<Array<LearningAreaWhereInput>>
  blogPosts?: InputMaybe<BlogPostManyRelationFilter>
  id?: InputMaybe<IdFilter>
  image?: InputMaybe<ImageAssetWhereInput>
  order?: InputMaybe<IntNullableFilter>
  printables?: InputMaybe<PrintableManyRelationFilter>
  sets?: InputMaybe<SetManyRelationFilter>
  tags?: InputMaybe<TagManyRelationFilter>
  title?: InputMaybe<StringFilter>
  videos?: InputMaybe<VideoManyRelationFilter>
}

export type LearningAreaWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type LocalStreamCreateInput = {
  streamable?: InputMaybe<FileFieldInput>
  title?: InputMaybe<Scalars['String']['input']>
}

export type LocalStreamOrderByInput = {
  id?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
}

export type LocalStreamUpdateArgs = {
  data: LocalStreamUpdateInput
  where: LocalStreamWhereUniqueInput
}

export type LocalStreamUpdateInput = {
  streamable?: InputMaybe<FileFieldInput>
  title?: InputMaybe<Scalars['String']['input']>
}

export type LocalStreamWhereInput = {
  AND?: InputMaybe<Array<LocalStreamWhereInput>>
  NOT?: InputMaybe<Array<LocalStreamWhereInput>>
  OR?: InputMaybe<Array<LocalStreamWhereInput>>
  id?: InputMaybe<IdFilter>
  title?: InputMaybe<StringFilter>
}

export type LocalStreamWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type LoginHistoryCreateInput = {
  browser?: InputMaybe<Scalars['String']['input']>
  browserFingerprint?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  customer?: InputMaybe<CustomerRelateToOneForCreateInput>
  device?: InputMaybe<Scalars['String']['input']>
  deviceType?: InputMaybe<Scalars['String']['input']>
  didTerminateExistingSessions?: InputMaybe<Scalars['Boolean']['input']>
  ipAddress?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<Scalars['JSON']['input']>
  os?: InputMaybe<Scalars['String']['input']>
  tabIdentifier?: InputMaybe<Scalars['String']['input']>
  user?: InputMaybe<UserRelateToOneForCreateInput>
  userAgent?: InputMaybe<Scalars['String']['input']>
}

export type LoginHistoryManyRelationFilter = {
  every?: InputMaybe<LoginHistoryWhereInput>
  none?: InputMaybe<LoginHistoryWhereInput>
  some?: InputMaybe<LoginHistoryWhereInput>
}

export type LoginHistoryOrderByInput = {
  browser?: InputMaybe<OrderDirection>
  browserFingerprint?: InputMaybe<OrderDirection>
  createdAt?: InputMaybe<OrderDirection>
  device?: InputMaybe<OrderDirection>
  deviceType?: InputMaybe<OrderDirection>
  didTerminateExistingSessions?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  ipAddress?: InputMaybe<OrderDirection>
  os?: InputMaybe<OrderDirection>
  tabIdentifier?: InputMaybe<OrderDirection>
  userAgent?: InputMaybe<OrderDirection>
}

export type LoginHistoryRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<LoginHistoryWhereUniqueInput>>
  create?: InputMaybe<Array<LoginHistoryCreateInput>>
}

export type LoginHistoryRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<LoginHistoryWhereUniqueInput>>
  create?: InputMaybe<Array<LoginHistoryCreateInput>>
  disconnect?: InputMaybe<Array<LoginHistoryWhereUniqueInput>>
  set?: InputMaybe<Array<LoginHistoryWhereUniqueInput>>
}

export type LoginHistoryUpdateArgs = {
  data: LoginHistoryUpdateInput
  where: LoginHistoryWhereUniqueInput
}

export type LoginHistoryUpdateInput = {
  browser?: InputMaybe<Scalars['String']['input']>
  browserFingerprint?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  customer?: InputMaybe<CustomerRelateToOneForUpdateInput>
  device?: InputMaybe<Scalars['String']['input']>
  deviceType?: InputMaybe<Scalars['String']['input']>
  didTerminateExistingSessions?: InputMaybe<Scalars['Boolean']['input']>
  ipAddress?: InputMaybe<Scalars['String']['input']>
  location?: InputMaybe<Scalars['JSON']['input']>
  os?: InputMaybe<Scalars['String']['input']>
  tabIdentifier?: InputMaybe<Scalars['String']['input']>
  user?: InputMaybe<UserRelateToOneForUpdateInput>
  userAgent?: InputMaybe<Scalars['String']['input']>
}

export type LoginHistoryWhereInput = {
  AND?: InputMaybe<Array<LoginHistoryWhereInput>>
  NOT?: InputMaybe<Array<LoginHistoryWhereInput>>
  OR?: InputMaybe<Array<LoginHistoryWhereInput>>
  browser?: InputMaybe<StringFilter>
  browserFingerprint?: InputMaybe<StringFilter>
  createdAt?: InputMaybe<DateTimeNullableFilter>
  customer?: InputMaybe<CustomerWhereInput>
  device?: InputMaybe<StringFilter>
  deviceType?: InputMaybe<StringFilter>
  didTerminateExistingSessions?: InputMaybe<BooleanFilter>
  id?: InputMaybe<IdFilter>
  ipAddress?: InputMaybe<StringFilter>
  os?: InputMaybe<StringFilter>
  tabIdentifier?: InputMaybe<StringFilter>
  user?: InputMaybe<UserWhereInput>
  userAgent?: InputMaybe<StringFilter>
}

export type LoginHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  not?: InputMaybe<NestedStringFilter>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export enum OrderDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type PageCreateInput = {
  content?: InputMaybe<Scalars['JSON']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type PageOrderByInput = {
  id?: InputMaybe<OrderDirection>
  slug?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
}

export type PageUpdateArgs = {
  data: PageUpdateInput
  where: PageWhereUniqueInput
}

export type PageUpdateInput = {
  content?: InputMaybe<Scalars['JSON']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type PageWhereInput = {
  AND?: InputMaybe<Array<PageWhereInput>>
  NOT?: InputMaybe<Array<PageWhereInput>>
  OR?: InputMaybe<Array<PageWhereInput>>
  id?: InputMaybe<IdFilter>
  slug?: InputMaybe<StringFilter>
  title?: InputMaybe<StringFilter>
}

export type PageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
}

export type PasswordFilter = {
  isSet: Scalars['Boolean']['input']
}

export enum PasswordResetRedemptionErrorCode {
  Failure = 'FAILURE',
  TokenExpired = 'TOKEN_EXPIRED',
  TokenRedeemed = 'TOKEN_REDEEMED'
}

export type PreviewInvoiceInput = {
  collectionMethod?: InputMaybe<Scalars['String']['input']>
  plan: Scalars['String']['input']
  subscriptionUserSeats?: InputMaybe<Scalars['Int']['input']>
}

export type PrintableCreateInput = {
  curriculumLink?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['JSON']['input']>
  eventLinks?: InputMaybe<EventLinkRelateToOneForCreateInput>
  fileAssetDownloads?: InputMaybe<FileAssetDownloadRelateToManyForCreateInput>
  fileAssetDownloadsCountCached?: InputMaybe<Scalars['Int']['input']>
  files?: InputMaybe<FileAssetRelateToManyForCreateInput>
  image?: InputMaybe<ImageAssetRelateToOneForCreateInput>
  isFreebie?: InputMaybe<Scalars['Boolean']['input']>
  learningAreas?: InputMaybe<LearningAreaRelateToManyForCreateInput>
  publishDate?: InputMaybe<Scalars['DateTime']['input']>
  sets?: InputMaybe<SetRelateToManyForCreateInput>
  slug?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagRelateToManyForCreateInput>
  title?: InputMaybe<Scalars['String']['input']>
  videos?: InputMaybe<VideoRelateToManyForCreateInput>
}

export type PrintableManyRelationFilter = {
  every?: InputMaybe<PrintableWhereInput>
  none?: InputMaybe<PrintableWhereInput>
  some?: InputMaybe<PrintableWhereInput>
}

export type PrintableOrderByInput = {
  curriculumLink?: InputMaybe<OrderDirection>
  fileAssetDownloadsCountCached?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  isFreebie?: InputMaybe<OrderDirection>
  publishDate?: InputMaybe<OrderDirection>
  slug?: InputMaybe<OrderDirection>
  status?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
}

export type PrintableRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<PrintableWhereUniqueInput>>
  create?: InputMaybe<Array<PrintableCreateInput>>
}

export type PrintableRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<PrintableWhereUniqueInput>>
  create?: InputMaybe<Array<PrintableCreateInput>>
  disconnect?: InputMaybe<Array<PrintableWhereUniqueInput>>
  set?: InputMaybe<Array<PrintableWhereUniqueInput>>
}

export type PrintableRelateToOneForCreateInput = {
  connect?: InputMaybe<PrintableWhereUniqueInput>
  create?: InputMaybe<PrintableCreateInput>
}

export type PrintableRelateToOneForUpdateInput = {
  connect?: InputMaybe<PrintableWhereUniqueInput>
  create?: InputMaybe<PrintableCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type PrintableUpdateArgs = {
  data: PrintableUpdateInput
  where: PrintableWhereUniqueInput
}

export type PrintableUpdateInput = {
  curriculumLink?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['JSON']['input']>
  eventLinks?: InputMaybe<EventLinkRelateToOneForUpdateInput>
  fileAssetDownloads?: InputMaybe<FileAssetDownloadRelateToManyForUpdateInput>
  fileAssetDownloadsCountCached?: InputMaybe<Scalars['Int']['input']>
  files?: InputMaybe<FileAssetRelateToManyForUpdateInput>
  image?: InputMaybe<ImageAssetRelateToOneForUpdateInput>
  isFreebie?: InputMaybe<Scalars['Boolean']['input']>
  learningAreas?: InputMaybe<LearningAreaRelateToManyForUpdateInput>
  publishDate?: InputMaybe<Scalars['DateTime']['input']>
  sets?: InputMaybe<SetRelateToManyForUpdateInput>
  slug?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagRelateToManyForUpdateInput>
  title?: InputMaybe<Scalars['String']['input']>
  videos?: InputMaybe<VideoRelateToManyForUpdateInput>
}

export type PrintableWhereInput = {
  AND?: InputMaybe<Array<PrintableWhereInput>>
  NOT?: InputMaybe<Array<PrintableWhereInput>>
  OR?: InputMaybe<Array<PrintableWhereInput>>
  curriculumLink?: InputMaybe<StringFilter>
  eventLinks?: InputMaybe<EventLinkWhereInput>
  fileAssetDownloads?: InputMaybe<FileAssetDownloadManyRelationFilter>
  fileAssetDownloadsCountCached?: InputMaybe<IntNullableFilter>
  files?: InputMaybe<FileAssetManyRelationFilter>
  id?: InputMaybe<IdFilter>
  image?: InputMaybe<ImageAssetWhereInput>
  isFreebie?: InputMaybe<BooleanFilter>
  learningAreas?: InputMaybe<LearningAreaManyRelationFilter>
  publishDate?: InputMaybe<DateTimeNullableFilter>
  sets?: InputMaybe<SetManyRelationFilter>
  slug?: InputMaybe<StringNullableFilter>
  status?: InputMaybe<StringNullableFilter>
  tags?: InputMaybe<TagManyRelationFilter>
  title?: InputMaybe<StringFilter>
  videos?: InputMaybe<VideoManyRelationFilter>
}

export type PrintableWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RedeemAccountInvitationTokenInput = {
  email: Scalars['String']['input']
  invitationToken: Scalars['String']['input']
  mode: InvitationMode
}

export enum S3ImagesExtension {
  Gif = 'gif',
  Jpg = 'jpg',
  Png = 'png',
  Webp = 'webp'
}

export type S3ImagesFieldInput = {
  ref?: InputMaybe<Scalars['String']['input']>
  upload?: InputMaybe<Scalars['Upload']['input']>
}

export enum S3ImagesSizeEnum {
  Base64 = 'base64',
  Full = 'full',
  Lg = 'lg',
  Md = 'md',
  Sm = 'sm'
}

export type SetCreateInput = {
  description?: InputMaybe<Scalars['JSON']['input']>
  image?: InputMaybe<ImageAssetRelateToOneForCreateInput>
  learningArea?: InputMaybe<LearningAreaRelateToOneForCreateInput>
  printables?: InputMaybe<PrintableRelateToManyForCreateInput>
  priority?: InputMaybe<Scalars['Int']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagRelateToManyForCreateInput>
  title?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  videos?: InputMaybe<VideoRelateToManyForCreateInput>
}

export type SetManyRelationFilter = {
  every?: InputMaybe<SetWhereInput>
  none?: InputMaybe<SetWhereInput>
  some?: InputMaybe<SetWhereInput>
}

export type SetOrderByInput = {
  id?: InputMaybe<OrderDirection>
  priority?: InputMaybe<OrderDirection>
  slug?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
  type?: InputMaybe<OrderDirection>
}

export type SetRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<SetWhereUniqueInput>>
  create?: InputMaybe<Array<SetCreateInput>>
}

export type SetRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<SetWhereUniqueInput>>
  create?: InputMaybe<Array<SetCreateInput>>
  disconnect?: InputMaybe<Array<SetWhereUniqueInput>>
  set?: InputMaybe<Array<SetWhereUniqueInput>>
}

export type SetUpdateArgs = {
  data: SetUpdateInput
  where: SetWhereUniqueInput
}

export type SetUpdateInput = {
  description?: InputMaybe<Scalars['JSON']['input']>
  image?: InputMaybe<ImageAssetRelateToOneForUpdateInput>
  learningArea?: InputMaybe<LearningAreaRelateToOneForUpdateInput>
  printables?: InputMaybe<PrintableRelateToManyForUpdateInput>
  priority?: InputMaybe<Scalars['Int']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagRelateToManyForUpdateInput>
  title?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<Scalars['String']['input']>
  videos?: InputMaybe<VideoRelateToManyForUpdateInput>
}

export type SetWhereInput = {
  AND?: InputMaybe<Array<SetWhereInput>>
  NOT?: InputMaybe<Array<SetWhereInput>>
  OR?: InputMaybe<Array<SetWhereInput>>
  id?: InputMaybe<IdFilter>
  image?: InputMaybe<ImageAssetWhereInput>
  learningArea?: InputMaybe<LearningAreaWhereInput>
  printables?: InputMaybe<PrintableManyRelationFilter>
  priority?: InputMaybe<IntNullableFilter>
  slug?: InputMaybe<StringFilter>
  tags?: InputMaybe<TagManyRelationFilter>
  title?: InputMaybe<StringFilter>
  type?: InputMaybe<StringFilter>
  videos?: InputMaybe<VideoManyRelationFilter>
}

export type SetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<QueryMode>
  not?: InputMaybe<NestedStringFilter>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>
  endsWith?: InputMaybe<Scalars['String']['input']>
  equals?: InputMaybe<Scalars['String']['input']>
  gt?: InputMaybe<Scalars['String']['input']>
  gte?: InputMaybe<Scalars['String']['input']>
  in?: InputMaybe<Array<Scalars['String']['input']>>
  lt?: InputMaybe<Scalars['String']['input']>
  lte?: InputMaybe<Scalars['String']['input']>
  mode?: InputMaybe<QueryMode>
  not?: InputMaybe<StringNullableFilter>
  notIn?: InputMaybe<Array<Scalars['String']['input']>>
  startsWith?: InputMaybe<Scalars['String']['input']>
}

export type SubscriptionCreateInput = {
  cancelAtPeriodEnd?: InputMaybe<Scalars['Boolean']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  currentPeriodEnd?: InputMaybe<Scalars['DateTime']['input']>
  currentPeriodStart?: InputMaybe<Scalars['DateTime']['input']>
  customer?: InputMaybe<CustomerRelateToOneForCreateInput>
  stripeSubscriptionId?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type SubscriptionManyRelationFilter = {
  every?: InputMaybe<SubscriptionWhereInput>
  none?: InputMaybe<SubscriptionWhereInput>
  some?: InputMaybe<SubscriptionWhereInput>
}

export type SubscriptionOrderByInput = {
  cancelAtPeriodEnd?: InputMaybe<OrderDirection>
  createdAt?: InputMaybe<OrderDirection>
  currency?: InputMaybe<OrderDirection>
  currentPeriodEnd?: InputMaybe<OrderDirection>
  currentPeriodStart?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  stripeSubscriptionId?: InputMaybe<OrderDirection>
  subscriptionStatus?: InputMaybe<OrderDirection>
  updatedAt?: InputMaybe<OrderDirection>
}

export type SubscriptionRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>
  create?: InputMaybe<Array<SubscriptionCreateInput>>
}

export type SubscriptionRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>
  create?: InputMaybe<Array<SubscriptionCreateInput>>
  disconnect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>
  set?: InputMaybe<Array<SubscriptionWhereUniqueInput>>
}

export type SubscriptionRelateToOneForCreateInput = {
  connect?: InputMaybe<SubscriptionWhereUniqueInput>
  create?: InputMaybe<SubscriptionCreateInput>
}

export type SubscriptionRelateToOneForUpdateInput = {
  connect?: InputMaybe<SubscriptionWhereUniqueInput>
  create?: InputMaybe<SubscriptionCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type SubscriptionUpdateArgs = {
  data: SubscriptionUpdateInput
  where: SubscriptionWhereUniqueInput
}

export type SubscriptionUpdateInput = {
  cancelAtPeriodEnd?: InputMaybe<Scalars['Boolean']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  currentPeriodEnd?: InputMaybe<Scalars['DateTime']['input']>
  currentPeriodStart?: InputMaybe<Scalars['DateTime']['input']>
  customer?: InputMaybe<CustomerRelateToOneForUpdateInput>
  stripeSubscriptionId?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
}

export type SubscriptionWhereInput = {
  AND?: InputMaybe<Array<SubscriptionWhereInput>>
  NOT?: InputMaybe<Array<SubscriptionWhereInput>>
  OR?: InputMaybe<Array<SubscriptionWhereInput>>
  cancelAtPeriodEnd?: InputMaybe<BooleanFilter>
  createdAt?: InputMaybe<DateTimeFilter>
  currency?: InputMaybe<StringFilter>
  currentPeriodEnd?: InputMaybe<DateTimeFilter>
  currentPeriodStart?: InputMaybe<DateTimeFilter>
  customer?: InputMaybe<CustomerWhereInput>
  id?: InputMaybe<IdFilter>
  stripeSubscriptionId?: InputMaybe<StringFilter>
  subscriptionStatus?: InputMaybe<StringFilter>
  updatedAt?: InputMaybe<DateTimeNullableFilter>
}

export type SubscriptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  stripeSubscriptionId?: InputMaybe<Scalars['String']['input']>
}

export type SubtitleAssetCreateInput = {
  closedCaptions?: InputMaybe<Scalars['Boolean']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  displayName?: InputMaybe<Scalars['String']['input']>
  file?: InputMaybe<FileFieldInput>
  languageCode?: InputMaybe<Scalars['String']['input']>
  muxAssetId?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  videoAsset?: InputMaybe<VideoAssetRelateToOneForCreateInput>
}

export type SubtitleAssetManyRelationFilter = {
  every?: InputMaybe<SubtitleAssetWhereInput>
  none?: InputMaybe<SubtitleAssetWhereInput>
  some?: InputMaybe<SubtitleAssetWhereInput>
}

export type SubtitleAssetOrderByInput = {
  closedCaptions?: InputMaybe<OrderDirection>
  createdAt?: InputMaybe<OrderDirection>
  displayName?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  languageCode?: InputMaybe<OrderDirection>
  muxAssetId?: InputMaybe<OrderDirection>
  name?: InputMaybe<OrderDirection>
}

export type SubtitleAssetRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<SubtitleAssetWhereUniqueInput>>
  create?: InputMaybe<Array<SubtitleAssetCreateInput>>
}

export type SubtitleAssetRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<SubtitleAssetWhereUniqueInput>>
  create?: InputMaybe<Array<SubtitleAssetCreateInput>>
  disconnect?: InputMaybe<Array<SubtitleAssetWhereUniqueInput>>
  set?: InputMaybe<Array<SubtitleAssetWhereUniqueInput>>
}

export type SubtitleAssetUpdateArgs = {
  data: SubtitleAssetUpdateInput
  where: SubtitleAssetWhereUniqueInput
}

export type SubtitleAssetUpdateInput = {
  closedCaptions?: InputMaybe<Scalars['Boolean']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  displayName?: InputMaybe<Scalars['String']['input']>
  file?: InputMaybe<FileFieldInput>
  languageCode?: InputMaybe<Scalars['String']['input']>
  muxAssetId?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  videoAsset?: InputMaybe<VideoAssetRelateToOneForUpdateInput>
}

export type SubtitleAssetWhereInput = {
  AND?: InputMaybe<Array<SubtitleAssetWhereInput>>
  NOT?: InputMaybe<Array<SubtitleAssetWhereInput>>
  OR?: InputMaybe<Array<SubtitleAssetWhereInput>>
  closedCaptions?: InputMaybe<BooleanFilter>
  createdAt?: InputMaybe<DateTimeNullableFilter>
  displayName?: InputMaybe<StringFilter>
  id?: InputMaybe<IdFilter>
  languageCode?: InputMaybe<StringNullableFilter>
  muxAssetId?: InputMaybe<StringNullableFilter>
  name?: InputMaybe<StringFilter>
  videoAsset?: InputMaybe<VideoAssetWhereInput>
}

export type SubtitleAssetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  muxAssetId?: InputMaybe<Scalars['String']['input']>
}

export type TagCreateInput = {
  blogPosts?: InputMaybe<BlogPostRelateToManyForCreateInput>
  learningAreas?: InputMaybe<LearningAreaRelateToManyForCreateInput>
  name?: InputMaybe<Scalars['String']['input']>
  printables?: InputMaybe<PrintableRelateToManyForCreateInput>
  sets?: InputMaybe<SetRelateToManyForCreateInput>
  videos?: InputMaybe<VideoRelateToManyForCreateInput>
}

export type TagManyRelationFilter = {
  every?: InputMaybe<TagWhereInput>
  none?: InputMaybe<TagWhereInput>
  some?: InputMaybe<TagWhereInput>
}

export type TagOrderByInput = {
  id?: InputMaybe<OrderDirection>
  name?: InputMaybe<OrderDirection>
}

export type TagRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>
  create?: InputMaybe<Array<TagCreateInput>>
}

export type TagRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<TagWhereUniqueInput>>
  create?: InputMaybe<Array<TagCreateInput>>
  disconnect?: InputMaybe<Array<TagWhereUniqueInput>>
  set?: InputMaybe<Array<TagWhereUniqueInput>>
}

export type TagUpdateArgs = {
  data: TagUpdateInput
  where: TagWhereUniqueInput
}

export type TagUpdateInput = {
  blogPosts?: InputMaybe<BlogPostRelateToManyForUpdateInput>
  learningAreas?: InputMaybe<LearningAreaRelateToManyForUpdateInput>
  name?: InputMaybe<Scalars['String']['input']>
  printables?: InputMaybe<PrintableRelateToManyForUpdateInput>
  sets?: InputMaybe<SetRelateToManyForUpdateInput>
  videos?: InputMaybe<VideoRelateToManyForUpdateInput>
}

export type TagWhereInput = {
  AND?: InputMaybe<Array<TagWhereInput>>
  NOT?: InputMaybe<Array<TagWhereInput>>
  OR?: InputMaybe<Array<TagWhereInput>>
  blogPosts?: InputMaybe<BlogPostManyRelationFilter>
  id?: InputMaybe<IdFilter>
  learningAreas?: InputMaybe<LearningAreaManyRelationFilter>
  name?: InputMaybe<StringFilter>
  printables?: InputMaybe<PrintableManyRelationFilter>
  sets?: InputMaybe<SetManyRelationFilter>
  videos?: InputMaybe<VideoManyRelationFilter>
}

export type TagWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type UpdateAccountInput = {
  address?: InputMaybe<CustomerAddressInput>
  billingEmail?: InputMaybe<Scalars['String']['input']>
  collectionMethod?: InputMaybe<Scalars['String']['input']>
  plan?: InputMaybe<Scalars['String']['input']>
  purchaseOrderNumber?: InputMaybe<Scalars['String']['input']>
  schoolName?: InputMaybe<Scalars['String']['input']>
  stripePaymentMethodId?: InputMaybe<Scalars['String']['input']>
  subscriptionUserSeats?: InputMaybe<Scalars['Int']['input']>
  taxNumber?: InputMaybe<Scalars['String']['input']>
}

export type UpdateAddressInput = {
  city: Scalars['String']['input']
  country: Scalars['String']['input']
  postcode: Scalars['String']['input']
  state?: InputMaybe<Scalars['String']['input']>
  street1: Scalars['String']['input']
}

export type UserCreateInput = {
  accessPermissions?: InputMaybe<CustomerAccessPermissionRelateToManyForCreateInput>
  country?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  customerId?: InputMaybe<Scalars['String']['input']>
  educationalRole?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  favouriteVideos?: InputMaybe<UserVideoFavouriteRelateToManyForCreateInput>
  fileAssetDownloads?: InputMaybe<FileAssetDownloadRelateToManyForCreateInput>
  fileAssetDownloadsCountCached?: InputMaybe<Scalars['Int']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  isFromWp?: InputMaybe<Scalars['Boolean']['input']>
  isKeystoneAdmin?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  loginCount?: InputMaybe<Scalars['Int']['input']>
  loginHistory?: InputMaybe<LoginHistoryRelateToManyForCreateInput>
  name?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  passwordResetIssuedAt?: InputMaybe<Scalars['DateTime']['input']>
  passwordResetRedeemedAt?: InputMaybe<Scalars['DateTime']['input']>
  passwordResetToken?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  school?: InputMaybe<Scalars['String']['input']>
  sessionIds?: InputMaybe<Scalars['JSON']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  subscriptionId?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  validTo?: InputMaybe<Scalars['DateTime']['input']>
  yearLevel?: InputMaybe<Scalars['String']['input']>
}

export type UserOrderByInput = {
  country?: InputMaybe<OrderDirection>
  createdAt?: InputMaybe<OrderDirection>
  customerId?: InputMaybe<OrderDirection>
  educationalRole?: InputMaybe<OrderDirection>
  email?: InputMaybe<OrderDirection>
  fileAssetDownloadsCountCached?: InputMaybe<OrderDirection>
  firstName?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  isFromWp?: InputMaybe<OrderDirection>
  isKeystoneAdmin?: InputMaybe<OrderDirection>
  lastName?: InputMaybe<OrderDirection>
  loginCount?: InputMaybe<OrderDirection>
  name?: InputMaybe<OrderDirection>
  passwordResetIssuedAt?: InputMaybe<OrderDirection>
  passwordResetRedeemedAt?: InputMaybe<OrderDirection>
  phone?: InputMaybe<OrderDirection>
  school?: InputMaybe<OrderDirection>
  state?: InputMaybe<OrderDirection>
  subscriptionId?: InputMaybe<OrderDirection>
  subscriptionStatus?: InputMaybe<OrderDirection>
  updatedAt?: InputMaybe<OrderDirection>
  validTo?: InputMaybe<OrderDirection>
  yearLevel?: InputMaybe<OrderDirection>
}

export type UserRelateToOneForCreateInput = {
  connect?: InputMaybe<UserWhereUniqueInput>
  create?: InputMaybe<UserCreateInput>
}

export type UserRelateToOneForUpdateInput = {
  connect?: InputMaybe<UserWhereUniqueInput>
  create?: InputMaybe<UserCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type UserUpdateArgs = {
  data: UserUpdateInput
  where: UserWhereUniqueInput
}

export type UserUpdateInput = {
  accessPermissions?: InputMaybe<CustomerAccessPermissionRelateToManyForUpdateInput>
  country?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  customerId?: InputMaybe<Scalars['String']['input']>
  educationalRole?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  favouriteVideos?: InputMaybe<UserVideoFavouriteRelateToManyForUpdateInput>
  fileAssetDownloads?: InputMaybe<FileAssetDownloadRelateToManyForUpdateInput>
  fileAssetDownloadsCountCached?: InputMaybe<Scalars['Int']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  isFromWp?: InputMaybe<Scalars['Boolean']['input']>
  isKeystoneAdmin?: InputMaybe<Scalars['Boolean']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  loginCount?: InputMaybe<Scalars['Int']['input']>
  loginHistory?: InputMaybe<LoginHistoryRelateToManyForUpdateInput>
  name?: InputMaybe<Scalars['String']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  passwordResetIssuedAt?: InputMaybe<Scalars['DateTime']['input']>
  passwordResetRedeemedAt?: InputMaybe<Scalars['DateTime']['input']>
  passwordResetToken?: InputMaybe<Scalars['String']['input']>
  phone?: InputMaybe<Scalars['String']['input']>
  school?: InputMaybe<Scalars['String']['input']>
  sessionIds?: InputMaybe<Scalars['JSON']['input']>
  state?: InputMaybe<Scalars['String']['input']>
  subscriptionId?: InputMaybe<Scalars['String']['input']>
  subscriptionStatus?: InputMaybe<Scalars['String']['input']>
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>
  validTo?: InputMaybe<Scalars['DateTime']['input']>
  yearLevel?: InputMaybe<Scalars['String']['input']>
}

export type UserVideoFavouriteCreateInput = {
  position?: InputMaybe<Scalars['Int']['input']>
  user?: InputMaybe<UserRelateToOneForCreateInput>
  video?: InputMaybe<VideoRelateToOneForCreateInput>
}

export type UserVideoFavouriteManyRelationFilter = {
  every?: InputMaybe<UserVideoFavouriteWhereInput>
  none?: InputMaybe<UserVideoFavouriteWhereInput>
  some?: InputMaybe<UserVideoFavouriteWhereInput>
}

export type UserVideoFavouriteOrderByInput = {
  id?: InputMaybe<OrderDirection>
  position?: InputMaybe<OrderDirection>
}

export type UserVideoFavouriteRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<UserVideoFavouriteWhereUniqueInput>>
  create?: InputMaybe<Array<UserVideoFavouriteCreateInput>>
}

export type UserVideoFavouriteRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<UserVideoFavouriteWhereUniqueInput>>
  create?: InputMaybe<Array<UserVideoFavouriteCreateInput>>
  disconnect?: InputMaybe<Array<UserVideoFavouriteWhereUniqueInput>>
  set?: InputMaybe<Array<UserVideoFavouriteWhereUniqueInput>>
}

export type UserVideoFavouriteUpdateArgs = {
  data: UserVideoFavouriteUpdateInput
  where: UserVideoFavouriteWhereUniqueInput
}

export type UserVideoFavouriteUpdateInput = {
  position?: InputMaybe<Scalars['Int']['input']>
  user?: InputMaybe<UserRelateToOneForUpdateInput>
  video?: InputMaybe<VideoRelateToOneForUpdateInput>
}

export type UserVideoFavouriteWhereInput = {
  AND?: InputMaybe<Array<UserVideoFavouriteWhereInput>>
  NOT?: InputMaybe<Array<UserVideoFavouriteWhereInput>>
  OR?: InputMaybe<Array<UserVideoFavouriteWhereInput>>
  id?: InputMaybe<IdFilter>
  position?: InputMaybe<IntNullableFilter>
  user?: InputMaybe<UserWhereInput>
  video?: InputMaybe<VideoWhereInput>
}

export type UserVideoFavouriteWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
}

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>
  NOT?: InputMaybe<Array<UserWhereInput>>
  OR?: InputMaybe<Array<UserWhereInput>>
  accessPermissions?: InputMaybe<CustomerAccessPermissionManyRelationFilter>
  country?: InputMaybe<StringFilter>
  createdAt?: InputMaybe<DateTimeFilter>
  customerId?: InputMaybe<StringNullableFilter>
  educationalRole?: InputMaybe<StringNullableFilter>
  email?: InputMaybe<StringFilter>
  favouriteVideos?: InputMaybe<UserVideoFavouriteManyRelationFilter>
  fileAssetDownloads?: InputMaybe<FileAssetDownloadManyRelationFilter>
  fileAssetDownloadsCountCached?: InputMaybe<IntNullableFilter>
  firstName?: InputMaybe<StringNullableFilter>
  id?: InputMaybe<IdFilter>
  isFromWp?: InputMaybe<BooleanFilter>
  isKeystoneAdmin?: InputMaybe<BooleanFilter>
  lastName?: InputMaybe<StringNullableFilter>
  loginCount?: InputMaybe<IntNullableFilter>
  loginHistory?: InputMaybe<LoginHistoryManyRelationFilter>
  name?: InputMaybe<StringFilter>
  password?: InputMaybe<PasswordFilter>
  passwordResetIssuedAt?: InputMaybe<DateTimeNullableFilter>
  passwordResetRedeemedAt?: InputMaybe<DateTimeNullableFilter>
  passwordResetToken?: InputMaybe<PasswordFilter>
  phone?: InputMaybe<StringNullableFilter>
  school?: InputMaybe<StringFilter>
  state?: InputMaybe<StringNullableFilter>
  subscriptionId?: InputMaybe<StringNullableFilter>
  subscriptionStatus?: InputMaybe<StringNullableFilter>
  updatedAt?: InputMaybe<DateTimeNullableFilter>
  validTo?: InputMaybe<DateTimeNullableFilter>
  yearLevel?: InputMaybe<StringNullableFilter>
}

export type UserWhereUniqueInput = {
  customerId?: InputMaybe<Scalars['String']['input']>
  email?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type VideoAssetCreateInput = {
  assetId?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  customThumbnail?: InputMaybe<ImageAssetRelateToOneForCreateInput>
  duration?: InputMaybe<Scalars['Float']['input']>
  errorMessage?: InputMaybe<Scalars['String']['input']>
  gifEndTime?: InputMaybe<Scalars['Float']['input']>
  gifStartTime?: InputMaybe<Scalars['Float']['input']>
  isReady?: InputMaybe<Scalars['Boolean']['input']>
  playbackId?: InputMaybe<Scalars['String']['input']>
  subtitles?: InputMaybe<SubtitleAssetRelateToManyForCreateInput>
  thumbnailTime?: InputMaybe<Scalars['Float']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  uploadId?: InputMaybe<Scalars['String']['input']>
  video?: InputMaybe<VideoRelateToOneForCreateInput>
}

export type VideoAssetOrderByInput = {
  assetId?: InputMaybe<OrderDirection>
  createdAt?: InputMaybe<OrderDirection>
  duration?: InputMaybe<OrderDirection>
  errorMessage?: InputMaybe<OrderDirection>
  gifEndTime?: InputMaybe<OrderDirection>
  gifStartTime?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  isReady?: InputMaybe<OrderDirection>
  playbackId?: InputMaybe<OrderDirection>
  thumbnailTime?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
  uploadId?: InputMaybe<OrderDirection>
}

export type VideoAssetRelateToOneForCreateInput = {
  connect?: InputMaybe<VideoAssetWhereUniqueInput>
  create?: InputMaybe<VideoAssetCreateInput>
}

export type VideoAssetRelateToOneForUpdateInput = {
  connect?: InputMaybe<VideoAssetWhereUniqueInput>
  create?: InputMaybe<VideoAssetCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type VideoAssetUpdateArgs = {
  data: VideoAssetUpdateInput
  where: VideoAssetWhereUniqueInput
}

export type VideoAssetUpdateInput = {
  assetId?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  customThumbnail?: InputMaybe<ImageAssetRelateToOneForUpdateInput>
  duration?: InputMaybe<Scalars['Float']['input']>
  errorMessage?: InputMaybe<Scalars['String']['input']>
  gifEndTime?: InputMaybe<Scalars['Float']['input']>
  gifStartTime?: InputMaybe<Scalars['Float']['input']>
  isReady?: InputMaybe<Scalars['Boolean']['input']>
  playbackId?: InputMaybe<Scalars['String']['input']>
  subtitles?: InputMaybe<SubtitleAssetRelateToManyForUpdateInput>
  thumbnailTime?: InputMaybe<Scalars['Float']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  uploadId?: InputMaybe<Scalars['String']['input']>
  video?: InputMaybe<VideoRelateToOneForUpdateInput>
}

export type VideoAssetWhereInput = {
  AND?: InputMaybe<Array<VideoAssetWhereInput>>
  NOT?: InputMaybe<Array<VideoAssetWhereInput>>
  OR?: InputMaybe<Array<VideoAssetWhereInput>>
  assetId?: InputMaybe<StringNullableFilter>
  createdAt?: InputMaybe<DateTimeNullableFilter>
  customThumbnail?: InputMaybe<ImageAssetWhereInput>
  duration?: InputMaybe<FloatNullableFilter>
  errorMessage?: InputMaybe<StringFilter>
  gifEndTime?: InputMaybe<FloatNullableFilter>
  gifStartTime?: InputMaybe<FloatNullableFilter>
  id?: InputMaybe<IdFilter>
  isReady?: InputMaybe<BooleanFilter>
  playbackId?: InputMaybe<StringFilter>
  subtitles?: InputMaybe<SubtitleAssetManyRelationFilter>
  thumbnailTime?: InputMaybe<FloatNullableFilter>
  title?: InputMaybe<StringFilter>
  uploadId?: InputMaybe<StringNullableFilter>
  video?: InputMaybe<VideoWhereInput>
}

export type VideoAssetWhereUniqueInput = {
  assetId?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  uploadId?: InputMaybe<Scalars['String']['input']>
}

export type VideoCreateInput = {
  curriculumLink?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['JSON']['input']>
  eventLinks?: InputMaybe<EventLinkRelateToOneForCreateInput>
  favouritedByUsers?: InputMaybe<UserVideoFavouriteRelateToManyForCreateInput>
  isFreebie?: InputMaybe<Scalars['Boolean']['input']>
  learningAreas?: InputMaybe<LearningAreaRelateToManyForCreateInput>
  printables?: InputMaybe<PrintableRelateToManyForCreateInput>
  priority?: InputMaybe<Scalars['Int']['input']>
  publishDate?: InputMaybe<Scalars['DateTime']['input']>
  sets?: InputMaybe<SetRelateToManyForCreateInput>
  slug?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagRelateToManyForCreateInput>
  title?: InputMaybe<Scalars['String']['input']>
  videoAsset?: InputMaybe<VideoAssetRelateToOneForCreateInput>
  vimeoId?: InputMaybe<Scalars['String']['input']>
}

export type VideoManyRelationFilter = {
  every?: InputMaybe<VideoWhereInput>
  none?: InputMaybe<VideoWhereInput>
  some?: InputMaybe<VideoWhereInput>
}

export type VideoOrderByInput = {
  curriculumLink?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  isFreebie?: InputMaybe<OrderDirection>
  priority?: InputMaybe<OrderDirection>
  publishDate?: InputMaybe<OrderDirection>
  slug?: InputMaybe<OrderDirection>
  status?: InputMaybe<OrderDirection>
  title?: InputMaybe<OrderDirection>
  vimeoId?: InputMaybe<OrderDirection>
}

export type VideoRelateToManyForCreateInput = {
  connect?: InputMaybe<Array<VideoWhereUniqueInput>>
  create?: InputMaybe<Array<VideoCreateInput>>
}

export type VideoRelateToManyForUpdateInput = {
  connect?: InputMaybe<Array<VideoWhereUniqueInput>>
  create?: InputMaybe<Array<VideoCreateInput>>
  disconnect?: InputMaybe<Array<VideoWhereUniqueInput>>
  set?: InputMaybe<Array<VideoWhereUniqueInput>>
}

export type VideoRelateToOneForCreateInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>
  create?: InputMaybe<VideoCreateInput>
}

export type VideoRelateToOneForUpdateInput = {
  connect?: InputMaybe<VideoWhereUniqueInput>
  create?: InputMaybe<VideoCreateInput>
  disconnect?: InputMaybe<Scalars['Boolean']['input']>
}

export type VideoUpdateArgs = {
  data: VideoUpdateInput
  where: VideoWhereUniqueInput
}

export type VideoUpdateInput = {
  curriculumLink?: InputMaybe<Scalars['String']['input']>
  description?: InputMaybe<Scalars['JSON']['input']>
  eventLinks?: InputMaybe<EventLinkRelateToOneForUpdateInput>
  favouritedByUsers?: InputMaybe<UserVideoFavouriteRelateToManyForUpdateInput>
  isFreebie?: InputMaybe<Scalars['Boolean']['input']>
  learningAreas?: InputMaybe<LearningAreaRelateToManyForUpdateInput>
  printables?: InputMaybe<PrintableRelateToManyForUpdateInput>
  priority?: InputMaybe<Scalars['Int']['input']>
  publishDate?: InputMaybe<Scalars['DateTime']['input']>
  sets?: InputMaybe<SetRelateToManyForUpdateInput>
  slug?: InputMaybe<Scalars['String']['input']>
  status?: InputMaybe<Scalars['String']['input']>
  tags?: InputMaybe<TagRelateToManyForUpdateInput>
  title?: InputMaybe<Scalars['String']['input']>
  videoAsset?: InputMaybe<VideoAssetRelateToOneForUpdateInput>
  vimeoId?: InputMaybe<Scalars['String']['input']>
}

export type VideoWhereInput = {
  AND?: InputMaybe<Array<VideoWhereInput>>
  NOT?: InputMaybe<Array<VideoWhereInput>>
  OR?: InputMaybe<Array<VideoWhereInput>>
  curriculumLink?: InputMaybe<StringFilter>
  eventLinks?: InputMaybe<EventLinkWhereInput>
  favouritedByUsers?: InputMaybe<UserVideoFavouriteManyRelationFilter>
  id?: InputMaybe<IdFilter>
  isFreebie?: InputMaybe<BooleanFilter>
  learningAreas?: InputMaybe<LearningAreaManyRelationFilter>
  printables?: InputMaybe<PrintableManyRelationFilter>
  priority?: InputMaybe<IntNullableFilter>
  publishDate?: InputMaybe<DateTimeFilter>
  sets?: InputMaybe<SetManyRelationFilter>
  slug?: InputMaybe<StringFilter>
  status?: InputMaybe<StringNullableFilter>
  tags?: InputMaybe<TagManyRelationFilter>
  title?: InputMaybe<StringFilter>
  videoAsset?: InputMaybe<VideoAssetWhereInput>
  vimeoId?: InputMaybe<StringNullableFilter>
}

export type VideoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']['input']>
  slug?: InputMaybe<Scalars['String']['input']>
  title?: InputMaybe<Scalars['String']['input']>
  vimeoId?: InputMaybe<Scalars['String']['input']>
}

export type WebhookEventCreateInput = {
  eventId?: InputMaybe<Scalars['String']['input']>
  loggedAt?: InputMaybe<Scalars['DateTime']['input']>
  responseCode?: InputMaybe<Scalars['Int']['input']>
  serviceName?: InputMaybe<Scalars['String']['input']>
}

export type WebhookEventOrderByInput = {
  eventId?: InputMaybe<OrderDirection>
  id?: InputMaybe<OrderDirection>
  loggedAt?: InputMaybe<OrderDirection>
  responseCode?: InputMaybe<OrderDirection>
  serviceName?: InputMaybe<OrderDirection>
}

export type WebhookEventUpdateArgs = {
  data: WebhookEventUpdateInput
  where: WebhookEventWhereUniqueInput
}

export type WebhookEventUpdateInput = {
  eventId?: InputMaybe<Scalars['String']['input']>
  loggedAt?: InputMaybe<Scalars['DateTime']['input']>
  responseCode?: InputMaybe<Scalars['Int']['input']>
  serviceName?: InputMaybe<Scalars['String']['input']>
}

export type WebhookEventWhereInput = {
  AND?: InputMaybe<Array<WebhookEventWhereInput>>
  NOT?: InputMaybe<Array<WebhookEventWhereInput>>
  OR?: InputMaybe<Array<WebhookEventWhereInput>>
  eventId?: InputMaybe<StringFilter>
  id?: InputMaybe<IdFilter>
  loggedAt?: InputMaybe<DateTimeFilter>
  responseCode?: InputMaybe<IntFilter>
  serviceName?: InputMaybe<StringFilter>
}

export type WebhookEventWhereUniqueInput = {
  eventId?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['ID']['input']>
}

export type GetBannerAlertQueryVariables = Exact<{
  where?: InputMaybe<BannerAlertWhereInput>
  orderBy?: InputMaybe<Array<BannerAlertOrderByInput> | BannerAlertOrderByInput>
}>

export type GetBannerAlertQuery = {
  __typename?: 'Query'
  bannerAlerts?: Array<{
    __typename?: 'BannerAlert'
    id: string
    kind?: string | null
    title?: string | null
    userDismissable?: boolean | null
    bodyText?: { __typename?: 'BannerAlert_bodyText_Document'; document: any } | null
  }> | null
}

export type LogDownloadMutationVariables = Exact<{
  fileAssetId: Scalars['String']['input']
  printableId?: InputMaybe<Scalars['String']['input']>
}>

export type LogDownloadMutation = {
  __typename?: 'Mutation'
  logDownload: {
    __typename?: 'BaseMutationResponse'
    success: boolean
    errors: Array<{ __typename?: 'UserError'; code?: string | null; message?: string | null }>
  }
}

export type RefreshSubscriptionMutationVariables = Exact<{ [key: string]: never }>

export type RefreshSubscriptionMutation = {
  __typename?: 'Mutation'
  refreshSubscription: {
    __typename?: 'RefreshSubscriptionMutationResponse'
    success: boolean
    errors: Array<{ __typename?: 'UserError'; code?: string | null; message?: string | null }>
    subscription?: { __typename?: 'Subscription'; subscriptionStatus: string; currentPeriodEnd: any } | null
  }
}

export type GetExtendedUserQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetExtendedUserQuery = {
  __typename?: 'Query'
  extendedUser?: {
    __typename?: 'User'
    id: string
    name: string
    firstName?: string | null
    lastName?: string | null
    email: string
    phone?: string | null
    educationalRole?: string | null
    yearLevel?: string | null
    createdAt: any
    favouriteVideosCount?: number | null
    favouriteVideos?: Array<{
      __typename?: 'UserVideoFavourite'
      id: string
      position?: number | null
      video?: {
        __typename?: 'Video'
        id: string
        title?: string | null
        slug?: string | null
        videoAsset?: {
          __typename?: 'VideoAsset'
          playbackId?: string | null
          thumbnailTime?: number | null
          duration?: number | null
          customThumbnail?: {
            __typename?: 'ImageAsset'
            imageFile?: { __typename?: 'S3ImagesFieldOutputType'; thumb?: string | null } | null
          } | null
        } | null
      } | null
    }> | null
  } | null
  extendedCustomer?: {
    __typename?: 'CurrentCustomer'
    id: string
    type: string
    plan: string
    frequency: string
    subscriptionUserSeats: number
    availableUserSeats: number
    signupStatus?: string | null
    subscriptionStatus?: string | null
    stripeCustomerId?: string | null
    validTo?: any | null
    hasFreeAccess: boolean
    billingEmail: string
    collectionMethod: string
    schoolName?: string | null
    schoolAddressStreet1?: string | null
    schoolAddressCity?: string | null
    schoolAddressState?: string | null
    schoolAddressPostcode?: string | null
    schoolAddressCountry?: string | null
    taxNumber?: string | null
    purchaseOrderNumber?: string | null
    seatsInUse: number
    customerAccessPermissions: Array<{
      __typename?: 'ExtendedCustomerAccessPermission'
      id: string
      createdAt: any
      isCustomerAdmin: boolean
      canViewContent: boolean
      invitationEmail?: string | null
      invitationIssuedAt?: any | null
      status: string
      user?: { __typename?: 'User'; id: string; name: string; email: string } | null
    }>
  } | null
  extendedCustomerAccessPermission?: {
    __typename?: 'CurrentCustomerAccessPermission'
    id: string
    isCustomerAdmin: boolean
    canViewContent: boolean
    status: string
  } | null
  extendedSubscription?: {
    __typename?: 'CurrentSubscription'
    id: string
    subscriptionStatus: string
    currentPeriodStart: any
    currentPeriodEnd: any
    cancelAtPeriodEnd: boolean
  } | null
}

export type ChangeBillingMethodMutationMutationVariables = Exact<{
  input: UpdateAccountInput
}>

export type ChangeBillingMethodMutationMutation = {
  __typename?: 'Mutation'
  updateAccount: {
    __typename?: 'UpdateAccountMutationResponse'
    success: boolean
    errors: Array<{ __typename?: 'UserError'; code?: string | null; message?: string | null }>
    customer?: { __typename?: 'Customer'; id: string } | null
  }
}

export type UpdateUserSeatsMutationMutationVariables = Exact<{
  input: UpdateAccountInput
}>

export type UpdateUserSeatsMutationMutation = {
  __typename?: 'Mutation'
  updateAccount: {
    __typename?: 'UpdateAccountMutationResponse'
    success: boolean
    errors: Array<{ __typename?: 'UserError'; code?: string | null; message?: string | null }>
    customer?: { __typename?: 'Customer'; subscriptionUserSeats: number } | null
  }
}

export type InviteUsersMutationMutationVariables = Exact<{
  input: InviteUsersInput
}>

export type InviteUsersMutationMutation = {
  __typename?: 'Mutation'
  inviteUsers: {
    __typename?: 'InviteUsersMutationResponse'
    success: boolean
    invitedEmails?: Array<string> | null
    errors: Array<{ __typename?: 'UserError'; code?: string | null; message?: string | null }>
  }
}

export type PreviewInvoiceMutationVariables = Exact<{
  input: PreviewInvoiceInput
}>

export type PreviewInvoiceMutation = {
  __typename?: 'Mutation'
  previewInvoice: {
    __typename?: 'PreviewInvoiceMutationResponse'
    success: boolean
    errors: Array<{ __typename?: 'UserError'; code?: string | null; message?: string | null }>
    invoicePreview?: {
      __typename?: 'InvoicePreview'
      customerId: string
      amountDue: number
      currency: string
      created: string
      dueDate?: string | null
      periodStart: string
      periodEnd: string
      appliedBalance: number
      total: number
      subtotal: number
      tax?: number | null
      collectionMethod: string
      status?: string | null
      lines: Array<{
        __typename?: 'InvoiceLineItem'
        id: string
        description?: string | null
        amount: number
        quantity?: number | null
        priceId?: string | null
      }>
    } | null
  }
}

export type MigrateToSchoolSubscriptionMutationMutationVariables = Exact<{
  input: UpdateAccountInput
}>

export type MigrateToSchoolSubscriptionMutationMutation = {
  __typename?: 'Mutation'
  updateAccount: {
    __typename?: 'UpdateAccountMutationResponse'
    success: boolean
    errors: Array<{ __typename?: 'UserError'; code?: string | null; message?: string | null }>
    customer?: { __typename?: 'Customer'; id: string } | null
  }
}

export type UpdateCustomerAccessPermissionMutationMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CustomerAccessPermissionUpdateInput
}>

export type UpdateCustomerAccessPermissionMutationMutation = {
  __typename?: 'Mutation'
  updateCustomerAccessPermission?: { __typename?: 'CustomerAccessPermission'; id: string } | null
}

export type DeleteCustomerAccessPermissionMutationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteCustomerAccessPermissionMutationMutation = {
  __typename?: 'Mutation'
  deleteCustomerAccessPermission?: { __typename?: 'CustomerAccessPermission'; id: string } | null
}

export type UpdatePersonalDetailsMutationMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UserUpdateInput
}>

export type UpdatePersonalDetailsMutationMutation = {
  __typename?: 'Mutation'
  updateUser?: { __typename?: 'User'; id: string } | null
}

export type UpdateSchoolDetailsMutationMutationVariables = Exact<{
  input: UpdateAccountInput
}>

export type UpdateSchoolDetailsMutationMutation = {
  __typename?: 'Mutation'
  updateAccount: {
    __typename?: 'UpdateAccountMutationResponse'
    success: boolean
    errors: Array<{ __typename?: 'UserError'; code?: string | null; message?: string | null }>
    customer?: { __typename?: 'Customer'; id: string } | null
  }
}

export type UpdatePasswordMutationMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type UpdatePasswordMutationMutation = {
  __typename?: 'Mutation'
  updateUser?: { __typename?: 'User'; id: string } | null
}

export type SendTokenEmailMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type SendTokenEmailMutation = { __typename?: 'Mutation'; sendUserPasswordResetLink: boolean }

export type PasswordResetMutationVariables = Exact<{
  email: Scalars['String']['input']
  token: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type PasswordResetMutation = {
  __typename?: 'Mutation'
  redeemUserPasswordResetToken?: {
    __typename?: 'RedeemUserPasswordResetTokenResult'
    code: PasswordResetRedemptionErrorCode
    message: string
  } | null
}

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCurrentUserQuery = {
  __typename?: 'Query'
  currentUser?: { __typename?: 'CurrentUser'; id: string; email: string; name: string } | null
  currentCustomer?: {
    __typename?: 'CurrentCustomer'
    id: string
    type: string
    plan: string
    schoolName?: string | null
    subscriptionUserSeats: number
    availableUserSeats: number
    signupStatus?: string | null
    stripeCustomerId?: string | null
    subscriptionStatus?: string | null
    validTo?: any | null
    hasFreeAccess: boolean
    collectionMethod: string
  } | null
  currentCustomerAccessPermission?: {
    __typename?: 'CurrentCustomerAccessPermission'
    id: string
    isCustomerAdmin: boolean
    canViewContent: boolean
    status: string
  } | null
  currentSubscription?: {
    __typename?: 'CurrentSubscription'
    id: string
    stripeSubscriptionId: string
    subscriptionStatus: string
    currentPeriodStart: any
    currentPeriodEnd: any
  } | null
}

export type LogInUserMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type LogInUserMutation = {
  __typename?: 'Mutation'
  authenticateUserWithPassword?:
    | { __typename: 'UserAuthenticationWithPasswordFailure'; message: string }
    | {
        __typename: 'UserAuthenticationWithPasswordSuccess'
        item: { __typename?: 'User'; id: string; authCode?: string | null }
      }
    | null
}

export type EndSessionMutationVariables = Exact<{ [key: string]: never }>

export type EndSessionMutation = { __typename?: 'Mutation'; endSession: boolean }

export type CheckUserQueryVariables = Exact<{
  email?: InputMaybe<Scalars['String']['input']>
}>

export type CheckUserQuery = { __typename?: 'Query'; user?: { __typename?: 'User'; email: string } | null }

export type GetSingleBlogPostQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type GetSingleBlogPostQuery = {
  __typename?: 'Query'
  blogPost?: {
    __typename?: 'BlogPost'
    id: string
    title?: string | null
    slug?: string | null
    publishDate?: any | null
    thumbnailImage?: {
      __typename?: 'ImageAsset'
      id: string
      imageFile?: { __typename?: 'S3ImagesFieldOutputType'; id: string; url?: string | null } | null
    } | null
    content?: { __typename?: 'BlogPost_content_Document'; document: any } | null
  } | null
}

export type GetMostRecentBlogArticlesQueryVariables = Exact<{
  where: BlogPostWhereInput
  skip?: InputMaybe<Scalars['Int']['input']>
  take?: InputMaybe<Scalars['Int']['input']>
}>

export type GetMostRecentBlogArticlesQuery = {
  __typename?: 'Query'
  blogPostsCount?: number | null
  blogPosts?: Array<{
    __typename?: 'BlogPost'
    title?: string | null
    slug?: string | null
    publishDate?: any | null
    id: string
    createdAt?: any | null
    thumbnailImage?: {
      __typename?: 'ImageAsset'
      imageFile?: { __typename?: 'S3ImagesFieldOutputType'; id: string; url?: string | null } | null
    } | null
    excerpt?: { __typename?: 'BlogPost_excerpt_Document'; document: any } | null
  }> | null
}

export type GetEventShowsQueryVariables = Exact<{
  eventsAfter?: InputMaybe<Scalars['DateTime']['input']>
}>

export type GetEventShowsQuery = {
  __typename?: 'Query'
  events?: Array<{
    __typename?: 'Event'
    title?: string | null
    eventLinksCount?: number | null
    bannerImage?: {
      __typename?: 'ImageAsset'
      name?: string | null
      imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
    } | null
    bodyText?: { __typename?: 'Event_bodyText_Document'; document: any } | null
    eventLinks?: Array<{
      __typename?: 'EventLink'
      id: string
      title?: string | null
      image?: {
        __typename?: 'ImageAsset'
        name?: string | null
        imageFile?: { __typename?: 'S3ImagesFieldOutputType'; urlMd?: string | null; url?: string | null } | null
      } | null
      printable?: {
        __typename?: 'Printable'
        id: string
        title?: string | null
        isFreebie?: boolean | null
        image?: {
          __typename?: 'ImageAsset'
          id: string
          name?: string | null
          imageFile?: { __typename?: 'S3ImagesFieldOutputType'; urlMd?: string | null; url?: string | null } | null
        } | null
        files?: Array<{
          __typename?: 'FileAsset'
          id: string
          state?: string | null
          file?: { __typename?: 'FileFieldOutput'; url: string } | null
        }> | null
      } | null
      video?: {
        __typename?: 'Video'
        id: string
        slug?: string | null
        videoAsset?: {
          __typename?: 'VideoAsset'
          customThumbnail?: {
            __typename?: 'ImageAsset'
            name?: string | null
            imageFile?: { __typename?: 'S3ImagesFieldOutputType'; urlMd?: string | null; url?: string | null } | null
          } | null
        } | null
      } | null
    }> | null
    eventLocations?: Array<{
      __typename?: 'EventLocation'
      id: string
      title?: string | null
      location?: string | null
      purchaseTicketLink?: string | null
      ticketAvailability?: string | null
      shows?: Array<{
        __typename?: 'EventShow'
        id: string
        startDate?: any | null
        endDate?: any | null
        timezone?: string | null
      }> | null
    }> | null
  }> | null
}

export type UserVideoFavouriteFragmentFragment = {
  __typename?: 'UserVideoFavourite'
  id: string
  position?: number | null
  user?: { __typename?: 'User'; id: string; name: string } | null
  video?: {
    __typename?: 'Video'
    id: string
    title?: string | null
    slug?: string | null
    setsCount?: number | null
    sets?: Array<{
      __typename?: 'Set'
      id: string
      title?: string | null
      slug?: string | null
      type?: string | null
    }> | null
    videoAsset?: {
      __typename?: 'VideoAsset'
      playbackId?: string | null
      thumbnailTime?: number | null
      duration?: number | null
      customThumbnail?: {
        __typename?: 'ImageAsset'
        imageFile?: { __typename?: 'S3ImagesFieldOutputType'; thumb?: string | null } | null
      } | null
    } | null
  } | null
} & { ' $fragmentName'?: 'UserVideoFavouriteFragmentFragment' }

export type UserVideoFavouritesQueryVariables = Exact<{
  id: Scalars['ID']['input']
  publishDate: Scalars['DateTime']['input']
}>

export type UserVideoFavouritesQuery = {
  __typename?: 'Query'
  userVideoFavourites?: Array<
    { __typename?: 'UserVideoFavourite' } & {
      ' $fragmentRefs'?: { UserVideoFavouriteFragmentFragment: UserVideoFavouriteFragmentFragment }
    }
  > | null
}

export type UpdateUserVideoFavouritesMutationVariables = Exact<{
  data: Array<UserVideoFavouriteUpdateArgs> | UserVideoFavouriteUpdateArgs
  publishDate: Scalars['DateTime']['input']
}>

export type UpdateUserVideoFavouritesMutation = {
  __typename?: 'Mutation'
  updateUserVideoFavourites?: Array<
    | ({ __typename?: 'UserVideoFavourite' } & {
        ' $fragmentRefs'?: { UserVideoFavouriteFragmentFragment: UserVideoFavouriteFragmentFragment }
      })
    | null
  > | null
}

export type DeleteUserVideoFavouriteMutationVariables = Exact<{
  id: Scalars['ID']['input']
  publishDate: Scalars['DateTime']['input']
}>

export type DeleteUserVideoFavouriteMutation = {
  __typename?: 'Mutation'
  deleteUserVideoFavourite?:
    | ({ __typename?: 'UserVideoFavourite' } & {
        ' $fragmentRefs'?: { UserVideoFavouriteFragmentFragment: UserVideoFavouriteFragmentFragment }
      })
    | null
}

export type GetAllHelpCategoriesQueryVariables = Exact<{ [key: string]: never }>

export type GetAllHelpCategoriesQuery = {
  __typename?: 'Query'
  helpCategories?: Array<{
    __typename?: 'HelpCategory'
    title?: string | null
    items?: Array<{
      __typename?: 'HelpItem'
      title?: string | null
      answer?: { __typename?: 'HelpItem_answer_Document'; document: any } | null
    }> | null
  }> | null
}

export type GetKeystonePageQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type GetKeystonePageQuery = {
  __typename?: 'Query'
  page?: {
    __typename?: 'Page'
    title?: string | null
    content?: { __typename?: 'Page_content_Document'; document: any } | null
  } | null
}

export type GetFileQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetFileQuery = {
  __typename?: 'Query'
  fileAsset?: { __typename?: 'FileAsset'; file?: { __typename?: 'FileFieldOutput'; url: string } | null } | null
}

export type GetSinglePrintableQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type GetSinglePrintableQuery = {
  __typename?: 'Query'
  printable?: {
    __typename?: 'Printable'
    id: string
    title?: string | null
    slug?: string | null
    status?: string | null
    publishDate?: any | null
    isFreebie?: boolean | null
    image?: {
      __typename?: 'ImageAsset'
      id: string
      imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
    } | null
    files?: Array<{
      __typename?: 'FileAsset'
      id: string
      state?: string | null
      file?: { __typename?: 'FileFieldOutput'; filesize: number; url: string } | null
    }> | null
  } | null
}

export type GetFilteredPrintablesQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>
  areas: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
}>

export type GetFilteredPrintablesQuery = {
  __typename?: 'Query'
  filteredPrintables: Array<{
    __typename?: 'FilteredPrintable'
    id: string
    title: string
    slug: string
    learningAreas: Array<string | null>
    isFreebie?: boolean | null
    image?: {
      __typename?: 'ImageAsset'
      id: string
      imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
    } | null
  } | null>
}

export type GetPrintableFilterOptionsQueryVariables = Exact<{ [key: string]: never }>

export type GetPrintableFilterOptionsQuery = {
  __typename?: 'Query'
  learningAreas?: Array<{
    __typename?: 'LearningArea'
    id: string
    title?: string | null
    order?: number | null
  }> | null
}

export type RedeemInvitationTokenMutationVariables = Exact<{
  email: Scalars['String']['input']
  invitationToken: Scalars['String']['input']
}>

export type RedeemInvitationTokenMutation = {
  __typename?: 'Mutation'
  redeemAccountInvitationToken: {
    __typename?: 'RedeemAccountInvitationTokenMutationResponse'
    success: boolean
    errors: Array<{ __typename?: 'UserError'; code?: string | null; message?: string | null }>
  }
}

export type CreateNewUserMutationVariables = Exact<{
  input: UserCreateInput
}>

export type CreateNewUserMutation = { __typename?: 'Mutation'; createUser?: { __typename?: 'User'; id: string } | null }

export type GetSubscriptionConfigQueryVariables = Exact<{ [key: string]: never }>

export type GetSubscriptionConfigQuery = {
  __typename?: 'Query'
  currentUser?: { __typename?: 'CurrentUser'; id: string; email: string } | null
  currentCustomer?: {
    __typename?: 'CurrentCustomer'
    id: string
    signupStatus?: string | null
    subscriptionStatus?: string | null
    stripeCustomerId?: string | null
    subscriptionUserSeats: number
    availableUserSeats: number
    type: string
    plan: string
    stripeTaxId?: string | null
    taxNumber?: string | null
    purchaseOrderNumber?: string | null
    hasFreeAccess: boolean
    collectionMethod: string
    customerAccessPermissions: Array<{
      __typename?: 'ExtendedCustomerAccessPermission'
      id: string
      isCustomerAdmin: boolean
      canViewContent: boolean
    }>
  } | null
  currentSubscription?: { __typename?: 'CurrentSubscription'; id: string; subscriptionStatus: string } | null
}

export type ResendActivationEmailMutationVariables = Exact<{ [key: string]: never }>

export type ResendActivationEmailMutation = {
  __typename?: 'Mutation'
  resendActivationEmail: {
    __typename?: 'BaseMutationResponse'
    success: boolean
    errors: Array<{ __typename?: 'UserError'; code?: string | null; message?: string | null }>
  }
}

export type CreateAccountMutationMutationVariables = Exact<{
  input: CreateAccountInput
}>

export type CreateAccountMutationMutation = {
  __typename?: 'Mutation'
  createAccount: {
    __typename?: 'CreateAccountMutationResponse'
    success: boolean
    errors: Array<{ __typename?: 'UserError'; code?: string | null; message?: string | null }>
    user?: { __typename?: 'User'; id: string } | null
  }
}

export type ValidateInvitationTokenMutationVariables = Exact<{
  email: Scalars['String']['input']
  invitationToken: Scalars['String']['input']
}>

export type ValidateInvitationTokenMutation = {
  __typename?: 'Mutation'
  redeemAccountInvitationToken: {
    __typename?: 'RedeemAccountInvitationTokenMutationResponse'
    success: boolean
    errors: Array<{ __typename?: 'UserError'; code?: string | null; message?: string | null }>
    invitationDetails?: {
      __typename?: 'InvitationDetails'
      customerId?: string | null
      customerName?: string | null
      isCustomerAdmin?: boolean | null
      canViewContent?: boolean | null
    } | null
  }
}

export type RedeemActivationTokenMutationVariables = Exact<{
  email: Scalars['String']['input']
  activationToken: Scalars['String']['input']
}>

export type RedeemActivationTokenMutation = {
  __typename?: 'Mutation'
  redeemAccountActivationToken: {
    __typename?: 'BaseMutationResponse'
    success: boolean
    errors: Array<{ __typename?: 'UserError'; code?: string | null; message?: string | null }>
  }
}

export type JoinImageQueryVariables = Exact<{
  joinImage1: Scalars['ID']['input']
  joinImage2: Scalars['ID']['input']
}>

export type JoinImageQuery = {
  __typename?: 'Query'
  right?: {
    __typename?: 'ImageAsset'
    imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
  } | null
  left?: {
    __typename?: 'ImageAsset'
    imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
  } | null
}

export type GetVideoPlaybackCheckQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type GetVideoPlaybackCheckQuery = {
  __typename?: 'Query'
  check?: {
    __typename?: 'Video'
    isFreebie?: boolean | null
    status?: string | null
    publishDate?: any | null
    videoAsset?: { __typename?: 'VideoAsset'; playbackId?: string | null } | null
  } | null
}

export type CheckIfVideoFavouritedQueryVariables = Exact<{
  userId: Scalars['ID']['input']
  slug: Scalars['String']['input']
}>

export type CheckIfVideoFavouritedQuery = {
  __typename?: 'Query'
  user?: {
    __typename?: 'User'
    id: string
    name: string
    favouriteVideos?: Array<{
      __typename?: 'UserVideoFavourite'
      id: string
      video?: { __typename?: 'Video'; id: string; title?: string | null } | null
    }> | null
  } | null
}

export type CreateUserVideoFavouriteForVideoMutationVariables = Exact<{
  slug: Scalars['String']['input']
  userId: Scalars['ID']['input']
  position?: InputMaybe<Scalars['Int']['input']>
}>

export type CreateUserVideoFavouriteForVideoMutation = {
  __typename?: 'Mutation'
  createUserVideoFavourite?: {
    __typename?: 'UserVideoFavourite'
    id: string
    position?: number | null
    user?: { __typename?: 'User'; id: string; name: string } | null
    video?: { __typename?: 'Video'; id: string; title?: string | null; slug?: string | null } | null
  } | null
}

export type DeleteUserVideoFavouriteForVideoMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteUserVideoFavouriteForVideoMutation = {
  __typename?: 'Mutation'
  deleteUserVideoFavourite?: {
    __typename?: 'UserVideoFavourite'
    id: string
    position?: number | null
    user?: { __typename?: 'User'; id: string; name: string } | null
    video?: { __typename?: 'Video'; id: string; title?: string | null; slug?: string | null } | null
  } | null
}

export type GetFilteredVideosQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>
  areas: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
}>

export type GetFilteredVideosQuery = {
  __typename?: 'Query'
  filteredVideos: Array<{
    __typename?: 'VideoOrSet'
    id: string
    slug: string
    setSlug?: string | null
    title: string
    learningAreas: Array<string | null>
    isSet: boolean
    imageAsset?: {
      __typename?: 'ImageAsset'
      id: string
      imageFile?: { __typename?: 'S3ImagesFieldOutputType'; id: string; url?: string | null } | null
    } | null
    videoAsset?: {
      __typename?: 'VideoAsset'
      id: string
      playbackId?: string | null
      gifEndTime?: number | null
      gifStartTime?: number | null
      thumbnailTime?: number | null
      customThumbnail?: {
        __typename?: 'ImageAsset'
        id: string
        imageFile?: { __typename?: 'S3ImagesFieldOutputType'; id: string; url?: string | null } | null
      } | null
    } | null
  } | null>
}

export type GetVideoQueryVariables = Exact<{
  slug: Scalars['String']['input']
  mostViewedIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  lastViewedIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  userId: Scalars['ID']['input']
  setSlug?: InputMaybe<Scalars['String']['input']>
  maxSets?: InputMaybe<Scalars['Int']['input']>
  publishDate?: InputMaybe<Scalars['DateTime']['input']>
  configId: Scalars['ID']['input']
}>

export type GetVideoQuery = {
  __typename?: 'Query'
  video?: {
    __typename?: 'Video'
    id: string
    status?: string | null
    publishDate?: any | null
    slug?: string | null
    title?: string | null
    description?: { __typename?: 'Video_description_Document'; document: any } | null
    favouritedByUsers?: Array<{
      __typename?: 'UserVideoFavourite'
      id: string
      user?: { __typename?: 'User'; id: string; name: string } | null
    }> | null
    videoAsset?: {
      __typename?: 'VideoAsset'
      playbackId?: string | null
      duration?: number | null
      thumbnailTime?: number | null
      customThumbnail?: {
        __typename?: 'ImageAsset'
        id: string
        imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
      } | null
    } | null
    printables?: Array<{
      __typename?: 'Printable'
      id: string
      title?: string | null
      isFreebie?: boolean | null
      image?: {
        __typename?: 'ImageAsset'
        id: string
        imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
      } | null
    }> | null
    learningAreas?: Array<{ __typename?: 'LearningArea'; id: string; title?: string | null }> | null
    sets?: Array<{
      __typename?: 'Set'
      title?: string | null
      type?: string | null
      slug?: string | null
      printables?: Array<{
        __typename?: 'Printable'
        id: string
        isFreebie?: boolean | null
        title?: string | null
        image?: {
          __typename?: 'ImageAsset'
          id: string
          imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
        } | null
      }> | null
      videos?: Array<{
        __typename?: 'Video'
        id: string
        slug?: string | null
        title?: string | null
        videoAsset?: {
          __typename?: 'VideoAsset'
          duration?: number | null
          playbackId?: string | null
          thumbnailTime?: number | null
          customThumbnail?: {
            __typename?: 'ImageAsset'
            id: string
            imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
          } | null
        } | null
      }> | null
    }> | null
  } | null
  favourites?: Array<{
    __typename?: 'UserVideoFavourite'
    position?: number | null
    video?: {
      __typename?: 'Video'
      id: string
      title?: string | null
      slug?: string | null
      sets?: Array<{
        __typename?: 'Set'
        title?: string | null
        type?: string | null
        slug?: string | null
        videos?: Array<{ __typename?: 'Video'; slug?: string | null }> | null
      }> | null
      videoAsset?: {
        __typename?: 'VideoAsset'
        duration?: number | null
        playbackId?: string | null
        thumbnailTime?: number | null
        customThumbnail?: {
          __typename?: 'ImageAsset'
          id: string
          imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
        } | null
      } | null
    } | null
  }> | null
  mostViewed?: Array<{
    __typename?: 'Video'
    id: string
    slug?: string | null
    title?: string | null
    videoAsset?: {
      __typename?: 'VideoAsset'
      duration?: number | null
      playbackId?: string | null
      thumbnailTime?: number | null
      customThumbnail?: {
        __typename?: 'ImageAsset'
        id: string
        imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
      } | null
    } | null
  }> | null
  lastViewed?: Array<{
    __typename?: 'Video'
    id: string
    slug?: string | null
    title?: string | null
    videoAsset?: {
      __typename?: 'VideoAsset'
      duration?: number | null
      playbackId?: string | null
      thumbnailTime?: number | null
      customThumbnail?: {
        __typename?: 'ImageAsset'
        id: string
        imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
      } | null
    } | null
  }> | null
  config?: {
    __typename?: 'Config'
    videoEndImage?: {
      __typename?: 'ImageAsset'
      id: string
      imageFile?: { __typename?: 'S3ImagesFieldOutputType'; id: string; url?: string | null } | null
    } | null
  } | null
}

export type GetVideoFilterOptionsQueryVariables = Exact<{ [key: string]: never }>

export type GetVideoFilterOptionsQuery = {
  __typename?: 'Query'
  learningAreas?: Array<{
    __typename?: 'LearningArea'
    id: string
    title?: string | null
    order?: number | null
  }> | null
}

export type GetPrintableDownloadsQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetPrintableDownloadsQuery = {
  __typename?: 'Query'
  printable?: {
    __typename?: 'Printable'
    id: string
    title?: string | null
    slug?: string | null
    status?: string | null
    publishDate?: any | null
    isFreebie?: boolean | null
    image?: {
      __typename?: 'ImageAsset'
      imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
    } | null
    files?: Array<{
      __typename?: 'FileAsset'
      id: string
      state?: string | null
      file?: { __typename?: 'FileFieldOutput'; filesize: number; url: string } | null
    }> | null
  } | null
}

export type SearchQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>
  publishDate?: InputMaybe<Scalars['DateTime']['input']>
}>

export type SearchQuery = {
  __typename?: 'Query'
  videos?: Array<{
    __typename?: 'Video'
    title?: string | null
    id: string
    slug?: string | null
    tags?: Array<{ __typename?: 'Tag'; id: string; name?: string | null }> | null
    videoAsset?: {
      __typename?: 'VideoAsset'
      id: string
      playbackId?: string | null
      thumbnailTime?: number | null
      customThumbnail?: {
        __typename?: 'ImageAsset'
        id: string
        imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
      } | null
    } | null
    sets?: Array<{ __typename?: 'Set'; slug?: string | null; type?: string | null }> | null
  }> | null
  sets?: Array<{
    __typename?: 'Set'
    title?: string | null
    id: string
    slug?: string | null
    image?: {
      __typename?: 'ImageAsset'
      imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
    } | null
  }> | null
  printables?: Array<{
    __typename?: 'Printable'
    id: string
    title?: string | null
    slug?: string | null
    tags?: Array<{ __typename?: 'Tag'; id: string; name?: string | null }> | null
    image?: {
      __typename?: 'ImageAsset'
      imageFile?: { __typename?: 'S3ImagesFieldOutputType'; url?: string | null } | null
    } | null
  }> | null
}

export type GetFirstSeriesVideoQueryVariables = Exact<{
  slug: Scalars['String']['input']
  publishDate: Scalars['DateTime']['input']
}>

export type GetFirstSeriesVideoQuery = {
  __typename?: 'Query'
  set?: {
    __typename?: 'Set'
    slug?: string | null
    videos?: Array<{ __typename?: 'Video'; slug?: string | null }> | null
  } | null
}

export type DeleteUserMutationVariables = Exact<{ [key: string]: never }>

export type DeleteUserMutation = { __typename?: 'Mutation'; deleteUser?: { __typename?: 'User'; id: string } | null }

export const UserVideoFavouriteFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoFavouriteFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoFavourite' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'setsCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sets' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'videos' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'some' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'status' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'equals' },
                                              value: { kind: 'StringValue', value: 'published', block: false }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'publishDate' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'lte' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: { kind: 'EnumValue', value: 'desc' }
                          }
                        ]
                      }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoAsset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'playbackId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customThumbnail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageFile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'thumb' },
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'md' }
                                      }
                                    ]
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserVideoFavouriteFragmentFragment, unknown>
export const GetBannerAlertDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBannerAlert' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BannerAlertWhereInput' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'BannerAlertOrderByInput' } }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bannerAlerts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'orderBy' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userDismissable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bodyText' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'document' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetBannerAlertQuery, GetBannerAlertQueryVariables>
export const LogDownloadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LogDownload' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fileAssetId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'printableId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logDownload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fileAssetId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fileAssetId' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'printableId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'printableId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<LogDownloadMutation, LogDownloadMutationVariables>
export const RefreshSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RefreshSubscription' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refreshSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStatus' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RefreshSubscriptionMutation, RefreshSubscriptionMutationVariables>
export const GetExtendedUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetExtendedUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'extendedUser' },
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'educationalRole' } },
                { kind: 'Field', name: { kind: 'Name', value: 'yearLevel' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'favouriteVideosCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'favouriteVideos' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'position' },
                                value: { kind: 'EnumValue', value: 'asc' }
                              }
                            ]
                          }
                        ]
                      }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'video' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videoAsset' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'playbackId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customThumbnail' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'imageFile' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'thumb' },
                                                name: { kind: 'Name', value: 'url' },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'size' },
                                                    value: { kind: 'EnumValue', value: 'md' }
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'thumbnailTime' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'duration' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'extendedCustomer' },
            name: { kind: 'Name', value: 'currentCustomer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionUserSeats' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableUserSeats' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signupStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'validTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasFreeAccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billingEmail' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionMethod' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schoolName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schoolAddressStreet1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schoolAddressCity' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schoolAddressState' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schoolAddressPostcode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schoolAddressCountry' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'purchaseOrderNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'seatsInUse' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerAccessPermissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCustomerAdmin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canViewContent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invitationEmail' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'invitationIssuedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'extendedCustomerAccessPermission' },
            name: { kind: 'Name', value: 'currentCustomerAccessPermission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCustomerAdmin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canViewContent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } }
              ]
            }
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'extendedSubscription' },
            name: { kind: 'Name', value: 'currentSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodStart' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } },
                { kind: 'Field', name: { kind: 'Name', value: 'cancelAtPeriodEnd' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetExtendedUserQuery, GetExtendedUserQueryVariables>
export const ChangeBillingMethodMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeBillingMethodMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAccountInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mode' },
                value: { kind: 'EnumValue', value: 'UPDATE_BILLING_METHOD' }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ChangeBillingMethodMutationMutation, ChangeBillingMethodMutationMutationVariables>
export const UpdateUserSeatsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserSeatsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAccountInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mode' },
                value: { kind: 'EnumValue', value: 'UPDATE_USER_SEATS' }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'subscriptionUserSeats' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateUserSeatsMutationMutation, UpdateUserSeatsMutationMutationVariables>
export const InviteUsersMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'InviteUsersMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'InviteUsersInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'inviteUsers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'invitedEmails' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<InviteUsersMutationMutation, InviteUsersMutationMutationVariables>
export const PreviewInvoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PreviewInvoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PreviewInvoiceInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'previewInvoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invoicePreview' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'amountDue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'created' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'periodStart' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'periodEnd' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lines' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'quantity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'priceId' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'appliedBalance' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'subtotal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tax' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'collectionMethod' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<PreviewInvoiceMutation, PreviewInvoiceMutationVariables>
export const MigrateToSchoolSubscriptionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MigrateToSchoolSubscriptionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAccountInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mode' },
                value: { kind: 'EnumValue', value: 'MIGRATE_PLAN' }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  MigrateToSchoolSubscriptionMutationMutation,
  MigrateToSchoolSubscriptionMutationMutationVariables
>
export const UpdateCustomerAccessPermissionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCustomerAccessPermissionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomerAccessPermissionUpdateInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCustomerAccessPermission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                    }
                  ]
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  UpdateCustomerAccessPermissionMutationMutation,
  UpdateCustomerAccessPermissionMutationMutationVariables
>
export const DeleteCustomerAccessPermissionMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCustomerAccessPermissionMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomerAccessPermission' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DeleteCustomerAccessPermissionMutationMutation,
  DeleteCustomerAccessPermissionMutationMutationVariables
>
export const UpdatePersonalDetailsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePersonalDetailsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserUpdateInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                    }
                  ]
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdatePersonalDetailsMutationMutation, UpdatePersonalDetailsMutationMutationVariables>
export const UpdateSchoolDetailsMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSchoolDetailsMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateAccountInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mode' },
                value: { kind: 'EnumValue', value: 'UPDATE_SCHOOL_DETAILS' }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateSchoolDetailsMutationMutation, UpdateSchoolDetailsMutationMutationVariables>
export const UpdatePasswordMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePasswordMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } }
                    }
                  ]
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'password' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdatePasswordMutationMutation, UpdatePasswordMutationMutationVariables>
export const SendTokenEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendTokenEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendUserPasswordResetLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SendTokenEmailMutation, SendTokenEmailMutationVariables>
export const PasswordResetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PasswordReset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemUserPasswordResetToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<PasswordResetMutation, PasswordResetMutationVariables>
export const GetCurrentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCurrentUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentCustomer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schoolName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionUserSeats' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableUserSeats' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signupStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'validTo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasFreeAccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionMethod' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentCustomerAccessPermission' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCustomerAdmin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canViewContent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stripeSubscriptionId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodStart' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currentPeriodEnd' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetCurrentUserQuery, GetCurrentUserQueryVariables>
export const LogInUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LogInUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'authenticateUserWithPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UserAuthenticationWithPasswordSuccess' }
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'item' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'authCode' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'UserAuthenticationWithPasswordFailure' }
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'message' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<LogInUserMutation, LogInUserMutationVariables>
export const EndSessionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EndSession' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'endSession' } }]
      }
    }
  ]
} as unknown as DocumentNode<EndSessionMutation, EndSessionMutationVariables>
export const CheckUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'email' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'email' } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CheckUserQuery, CheckUserQueryVariables>
export const GetSingleBlogPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSingleBlogPost' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blogPost' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thumbnailImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageFile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'S3ImagesFieldOutputType' }
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'lg' }
                                      }
                                    ]
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'hydrateRelationships' },
                            value: { kind: 'BooleanValue', value: true }
                          }
                        ]
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetSingleBlogPostQuery, GetSingleBlogPostQueryVariables>
export const GetMostRecentBlogArticlesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMostRecentBlogArticles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'where' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'BlogPostWhereInput' } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'take' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blogPostsCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
              }
            ]
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blogPosts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'where' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'skip' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'skip' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'take' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'take' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'publishDate' },
                      value: { kind: 'EnumValue', value: 'desc' }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thumbnailImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageFile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'S3ImagesFieldOutputType' }
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'lg' }
                                      }
                                    ]
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'excerpt' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'hydrateRelationships' },
                            value: { kind: 'BooleanValue', value: true }
                          }
                        ]
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetMostRecentBlogArticlesQuery, GetMostRecentBlogArticlesQueryVariables>
export const GetEventShowsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetEventShows' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'eventsAfter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'events' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'take' }, value: { kind: 'IntValue', value: '1' } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'createdAt' },
                      value: { kind: 'EnumValue', value: 'desc' }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bannerImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageFile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'size' },
                                  value: { kind: 'EnumValue', value: 'full' }
                                }
                              ]
                            }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'bodyText' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'document' } }]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eventLinks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageFile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'urlMd' },
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'md' }
                                      }
                                    ]
                                  },
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'url' },
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'full' }
                                      }
                                    ]
                                  }
                                ]
                              }
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'printable' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFreebie' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageFile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          alias: { kind: 'Name', value: 'urlMd' },
                                          name: { kind: 'Name', value: 'url' },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: { kind: 'Name', value: 'size' },
                                              value: { kind: 'EnumValue', value: 'md' }
                                            }
                                          ]
                                        },
                                        {
                                          kind: 'Field',
                                          alias: { kind: 'Name', value: 'url' },
                                          name: { kind: 'Name', value: 'url' },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: { kind: 'Name', value: 'size' },
                                              value: { kind: 'EnumValue', value: 'full' }
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                                ]
                              }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'files' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'file' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'video' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videoAsset' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customThumbnail' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'imageFile' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'urlMd' },
                                                name: { kind: 'Name', value: 'url' },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'size' },
                                                    value: { kind: 'EnumValue', value: 'md' }
                                                  }
                                                ]
                                              },
                                              {
                                                kind: 'Field',
                                                alias: { kind: 'Name', value: 'url' },
                                                name: { kind: 'Name', value: 'url' },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'size' },
                                                    value: { kind: 'EnumValue', value: 'full' }
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'eventLinksCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'eventLocations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'shows' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'some' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'startDate' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'gte' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'eventsAfter' } }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'purchaseTicketLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ticketAvailability' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shows' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'startDate' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'gte' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'eventsAfter' } }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'orderBy' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'startDate' },
                                  value: { kind: 'EnumValue', value: 'asc' }
                                }
                              ]
                            }
                          }
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'timezone' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetEventShowsQuery, GetEventShowsQueryVariables>
export const UserVideoFavouritesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserVideoFavourites' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userVideoFavourites' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'equals' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'position' },
                          value: { kind: 'EnumValue', value: 'asc' }
                        }
                      ]
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserVideoFavouriteFragment' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoFavouriteFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoFavourite' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'setsCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sets' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'videos' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'some' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'status' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'equals' },
                                              value: { kind: 'StringValue', value: 'published', block: false }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'publishDate' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'lte' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: { kind: 'EnumValue', value: 'desc' }
                          }
                        ]
                      }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoAsset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'playbackId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customThumbnail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageFile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'thumb' },
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'md' }
                                      }
                                    ]
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserVideoFavouritesQuery, UserVideoFavouritesQueryVariables>
export const UpdateUserVideoFavouritesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserVideoFavourites' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoFavouriteUpdateArgs' } }
              }
            }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUserVideoFavourites' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserVideoFavouriteFragment' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoFavouriteFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoFavourite' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'setsCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sets' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'videos' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'some' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'status' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'equals' },
                                              value: { kind: 'StringValue', value: 'published', block: false }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'publishDate' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'lte' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: { kind: 'EnumValue', value: 'desc' }
                          }
                        ]
                      }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoAsset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'playbackId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customThumbnail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageFile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'thumb' },
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'md' }
                                      }
                                    ]
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UpdateUserVideoFavouritesMutation, UpdateUserVideoFavouritesMutationVariables>
export const DeleteUserVideoFavouriteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUserVideoFavourite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserVideoFavourite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserVideoFavouriteFragment' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserVideoFavouriteFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserVideoFavourite' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'position' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'setsCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sets' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'videos' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'some' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'status' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'equals' },
                                              value: { kind: 'StringValue', value: 'published', block: false }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'publishDate' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'lte' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: { kind: 'EnumValue', value: 'desc' }
                          }
                        ]
                      }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoAsset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'playbackId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customThumbnail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageFile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    alias: { kind: 'Name', value: 'thumb' },
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'md' }
                                      }
                                    ]
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DeleteUserVideoFavouriteMutation, DeleteUserVideoFavouriteMutationVariables>
export const GetAllHelpCategoriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllHelpCategories' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'helpCategories' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'answer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'document' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'hydrateRelationships' },
                                  value: { kind: 'BooleanValue', value: true }
                                }
                              ]
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetAllHelpCategoriesQuery, GetAllHelpCategoriesQueryVariables>
export const GetKeystonePageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetKeystonePage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'page' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'document' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'hydrateRelationships' },
                            value: { kind: 'BooleanValue', value: true }
                          }
                        ]
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetKeystonePageQuery, GetKeystonePageQueryVariables>
export const GetFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fileAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetFileQuery, GetFileQueryVariables>
export const GetSinglePrintableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSinglePrintable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'printable' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFreebie' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageFile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'size' },
                                  value: { kind: 'EnumValue', value: 'full' }
                                }
                              ]
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'filesize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetSinglePrintableQuery, GetSinglePrintableQueryVariables>
export const GetFilteredPrintablesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFilteredPrintables' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areas' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filteredPrintables' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'areas' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areas' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'learningAreas' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFreebie' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageFile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'url' },
                              name: { kind: 'Name', value: 'url' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'size' },
                                  value: { kind: 'EnumValue', value: 'md' }
                                }
                              ]
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetFilteredPrintablesQuery, GetFilteredPrintablesQueryVariables>
export const GetPrintableFilterOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPrintableFilterOptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningAreas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'OR' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'printables' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'some' },
                                      value: { kind: 'ObjectValue', fields: [] }
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetPrintableFilterOptionsQuery, GetPrintableFilterOptionsQueryVariables>
export const RedeemInvitationTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RedeemInvitationToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invitationToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemAccountInvitationToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitationToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invitationToken' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'mode' }, value: { kind: 'EnumValue', value: 'REDEEM' } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RedeemInvitationTokenMutation, RedeemInvitationTokenMutationVariables>
export const CreateNewUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateNewUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserCreateInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateNewUserMutation, CreateNewUserMutationVariables>
export const GetSubscriptionConfigDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSubscriptionConfig' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentCustomer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signupStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stripeCustomerId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionUserSeats' } },
                { kind: 'Field', name: { kind: 'Name', value: 'availableUserSeats' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stripeTaxId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taxNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'purchaseOrderNumber' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasFreeAccess' } },
                { kind: 'Field', name: { kind: 'Name', value: 'collectionMethod' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customerAccessPermissions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCustomerAdmin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canViewContent' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentSubscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStatus' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetSubscriptionConfigQuery, GetSubscriptionConfigQueryVariables>
export const ResendActivationEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResendActivationEmail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resendActivationEmail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>
export const CreateAccountMutationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateAccountMutation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateAccountInput' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CreateAccountMutationMutation, CreateAccountMutationMutationVariables>
export const ValidateInvitationTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ValidateInvitationToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invitationToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemAccountInvitationToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitationToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invitationToken' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mode' },
                value: { kind: 'EnumValue', value: 'VALIDATE' }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitationDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'customerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'customerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isCustomerAdmin' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'canViewContent' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ValidateInvitationTokenMutation, ValidateInvitationTokenMutationVariables>
export const RedeemActivationTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RedeemActivationToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activationToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redeemAccountActivationToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activationToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'activationToken' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'errors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RedeemActivationTokenMutation, RedeemActivationTokenMutationVariables>
export const JoinImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'JoinImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'joinImage1' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'joinImage2' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'right' },
            name: { kind: 'Name', value: 'imageAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'joinImage1' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageFile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'url' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'EnumValue', value: 'lg' }
                          }
                        ]
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'left' },
            name: { kind: 'Name', value: 'imageAsset' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'joinImage2' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageFile' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'url' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'size' },
                            value: { kind: 'EnumValue', value: 'lg' }
                          }
                        ]
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<JoinImageQuery, JoinImageQueryVariables>
export const GetVideoPlaybackCheckDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetVideoPlaybackCheck' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'check' },
            name: { kind: 'Name', value: 'video' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isFreebie' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoAsset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'playbackId' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetVideoPlaybackCheckQuery, GetVideoPlaybackCheckQueryVariables>
export const CheckIfVideoFavouritedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckIfVideoFavourited' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'favouriteVideos' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'video' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'slug' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'contains' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'video' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CheckIfVideoFavouritedQuery, CheckIfVideoFavouritedQueryVariables>
export const CreateUserVideoFavouriteForVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUserVideoFavouriteForVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'position' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserVideoFavourite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'data' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'connect' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'id' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'video' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'connect' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'slug' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'position' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'position' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'video' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreateUserVideoFavouriteForVideoMutation,
  CreateUserVideoFavouriteForVideoMutationVariables
>
export const DeleteUserVideoFavouriteForVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUserVideoFavouriteForVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUserVideoFavourite' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'video' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'position' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  DeleteUserVideoFavouriteForVideoMutation,
  DeleteUserVideoFavouriteForVideoMutationVariables
>
export const GetFilteredVideosDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFilteredVideos' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'areas' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'filteredVideos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'search' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'areas' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'areas' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'setSlug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'learningAreas' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isSet' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'imageAsset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageFile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'size' },
                                  value: { kind: 'EnumValue', value: 'md' }
                                }
                              ]
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoAsset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbackId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gifEndTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'gifStartTime' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailTime' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customThumbnail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageFile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'md' }
                                      }
                                    ]
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetFilteredVideosQuery, GetFilteredVideosQueryVariables>
export const GetVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mostViewedIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastViewedIds' } },
          type: {
            kind: 'ListType',
            type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'setSlug' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'maxSets' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'configId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'video' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'description' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'document' } }]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'favouritedByUsers' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'user' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'id' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'equals' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoAsset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'playbackId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customThumbnail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageFile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'lg' }
                                      }
                                    ]
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailTime' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'printables' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'equals' },
                                  value: { kind: 'StringValue', value: 'published', block: false }
                                }
                              ]
                            }
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'publishDate' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'lte' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isFreebie' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageFile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'md' }
                                      }
                                    ]
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'learningAreas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sets' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'slug' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'equals' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'setSlug' } }
                                }
                              ]
                            }
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'videos' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'some' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'status' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'equals' },
                                              value: { kind: 'StringValue', value: 'published', block: false }
                                            }
                                          ]
                                        }
                                      },
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'publishDate' },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: { kind: 'Name', value: 'lte' },
                                              value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                                            }
                                          ]
                                        }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'priority' },
                            value: { kind: 'EnumValue', value: 'asc' }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'take' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'maxSets' } }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'printables' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'status' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'equals' },
                                        value: { kind: 'StringValue', value: 'published', block: false }
                                      }
                                    ]
                                  }
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'publishDate' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'lte' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isFreebie' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'image' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageFile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: { kind: 'Name', value: 'size' },
                                              value: { kind: 'EnumValue', value: 'md' }
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'videos' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'orderBy' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'title' },
                                  value: { kind: 'EnumValue', value: 'asc' }
                                }
                              ]
                            }
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'status' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'equals' },
                                        value: { kind: 'StringValue', value: 'published', block: false }
                                      }
                                    ]
                                  }
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'publishDate' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'lte' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                                      }
                                    ]
                                  }
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'videoAsset' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'id' },
                                        value: { kind: 'ObjectValue', fields: [] }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videoAsset' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'playbackId' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'customThumbnail' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'imageFile' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' },
                                                arguments: [
                                                  {
                                                    kind: 'Argument',
                                                    name: { kind: 'Name', value: 'size' },
                                                    value: { kind: 'EnumValue', value: 'sm' }
                                                  }
                                                ]
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'thumbnailTime' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'favourites' },
            name: { kind: 'Name', value: 'userVideoFavourites' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'user' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'equals' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'position' },
                          value: { kind: 'EnumValue', value: 'asc' }
                        }
                      ]
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'position' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'video' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sets' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'videos' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'orderBy' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'title' },
                                            value: { kind: 'EnumValue', value: 'asc' }
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'take' },
                                  value: { kind: 'IntValue', value: '1' }
                                }
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'videoAsset' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'playbackId' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'customThumbnail' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageFile' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'url' },
                                          arguments: [
                                            {
                                              kind: 'Argument',
                                              name: { kind: 'Name', value: 'size' },
                                              value: { kind: 'EnumValue', value: 'sm' }
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'thumbnailTime' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'mostViewed' },
            name: { kind: 'Name', value: 'videos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'equals' },
                            value: { kind: 'StringValue', value: 'published', block: false }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'publishDate' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'lte' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'mostViewedIds' } }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'videoAsset' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: { kind: 'ObjectValue', fields: [] }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoAsset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbackId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customThumbnail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageFile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'sm' }
                                      }
                                    ]
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailTime' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'lastViewed' },
            name: { kind: 'Name', value: 'videos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'equals' },
                            value: { kind: 'StringValue', value: 'published', block: false }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'publishDate' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'lte' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'in' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'lastViewedIds' } }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'videoAsset' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: { kind: 'ObjectValue', fields: [] }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoAsset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbackId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customThumbnail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageFile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'sm' }
                                      }
                                    ]
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailTime' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'config' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'configId' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoEndImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageFile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'size' },
                                  value: { kind: 'EnumValue', value: 'lg' }
                                }
                              ]
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetVideoQuery, GetVideoQueryVariables>
export const GetVideoFilterOptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetVideoFilterOptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'learningAreas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'OR' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'videos' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'some' },
                                      value: { kind: 'ObjectValue', fields: [] }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'sets' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'some' },
                                      value: { kind: 'ObjectValue', fields: [] }
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'order' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetVideoFilterOptionsQuery, GetVideoFilterOptionsQueryVariables>
export const GetPrintableDownloadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPrintableDownloads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'printable' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'publishDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isFreebie' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageFile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'size' },
                                  value: { kind: 'EnumValue', value: 'full' }
                                }
                              ]
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'files' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'state' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'filesize' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetPrintableDownloadsQuery, GetPrintableDownloadsQueryVariables>
export const SearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Search' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'search' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'videos' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'equals' },
                            value: { kind: 'StringValue', value: 'published', block: false }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'publishDate' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'lte' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'OR' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'contains' },
                                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } }
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'mode' },
                                      value: { kind: 'EnumValue', value: 'insensitive' }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'tags' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'some' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'name' },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: 'contains' },
                                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } }
                                                },
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: 'mode' },
                                                  value: { kind: 'EnumValue', value: 'insensitive' }
                                                }
                                              ]
                                            }
                                          }
                                        ]
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videoAsset' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'playbackId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customThumbnail' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageFile' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [
                                      {
                                        kind: 'Argument',
                                        name: { kind: 'Name', value: 'size' },
                                        value: { kind: 'EnumValue', value: 'sm' }
                                      }
                                    ]
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'thumbnailTime' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sets' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: { kind: 'EnumValue', value: 'asc' }
                          }
                        ]
                      }
                    }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sets' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'title' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'contains' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } }
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'mode' },
                            value: { kind: 'EnumValue', value: 'insensitive' }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'videos' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'some' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'status' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'equals' },
                                        value: { kind: 'StringValue', value: 'published', block: false }
                                      }
                                    ]
                                  }
                                },
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'publishDate' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: { kind: 'Name', value: 'lte' },
                                        value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                                      }
                                    ]
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageFile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'size' },
                                  value: { kind: 'EnumValue', value: 'sm' }
                                }
                              ]
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'printables' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'equals' },
                            value: { kind: 'StringValue', value: 'published', block: false }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'publishDate' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'lte' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'OR' },
                      value: {
                        kind: 'ListValue',
                        values: [
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'title' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'contains' },
                                      value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } }
                                    },
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'mode' },
                                      value: { kind: 'EnumValue', value: 'insensitive' }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            kind: 'ObjectValue',
                            fields: [
                              {
                                kind: 'ObjectField',
                                name: { kind: 'Name', value: 'tags' },
                                value: {
                                  kind: 'ObjectValue',
                                  fields: [
                                    {
                                      kind: 'ObjectField',
                                      name: { kind: 'Name', value: 'some' },
                                      value: {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'name' },
                                            value: {
                                              kind: 'ObjectValue',
                                              fields: [
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: 'contains' },
                                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'search' } }
                                                },
                                                {
                                                  kind: 'ObjectField',
                                                  name: { kind: 'Name', value: 'mode' },
                                                  value: { kind: 'EnumValue', value: 'insensitive' }
                                                }
                                              ]
                                            }
                                          }
                                        ]
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageFile' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'size' },
                                  value: { kind: 'EnumValue', value: 'sm' }
                                }
                              ]
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SearchQuery, SearchQueryVariables>
export const GetFirstSeriesVideoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFirstSeriesVideo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateTime' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'set' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'videos' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'status' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'equals' },
                                  value: { kind: 'StringValue', value: 'published', block: false }
                                }
                              ]
                            }
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'publishDate' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'lte' },
                                  value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orderBy' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'title' },
                            value: { kind: 'EnumValue', value: 'asc' }
                          }
                        ]
                      }
                    },
                    { kind: 'Argument', name: { kind: 'Name', value: 'take' }, value: { kind: 'IntValue', value: '1' } }
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetFirstSeriesVideoQuery, GetFirstSeriesVideoQueryVariables>
export const DeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'StringValue', value: 'some-fake-id', block: false }
                    }
                  ]
                }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>
