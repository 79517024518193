import { useMutation } from '@apollo/client'
import { graphql } from '~/graphql/codegen'

const FILE_ASSET_DOWNLOAD = graphql(`
  mutation LogDownload($fileAssetId: String!, $printableId: String) {
    logDownload(fileAssetId: $fileAssetId, printableId: $printableId) {
      success
      errors {
        code
        message
      }
    }
  }
`)

interface LogDownloadProps {
  fileAssetId: string
  printableId?: string | null
}

const useDownloadLogger = () => {
  const [log] = useMutation(FILE_ASSET_DOWNLOAD)

  const logDownload = async ({ printableId, fileAssetId }: LogDownloadProps) => {
    return await log({
      variables: {
        fileAssetId,
        printableId
      }
    })
  }

  return { logDownload }
}

export default useDownloadLogger
